import React, { memo } from 'react'
import styled from 'styled-components/macro'
import { parseISO, format } from 'date-fns'

import {
  Text,
  GHStyledLink as StyledLink,
  ReactTable,
  TruncatedText,
} from '@gitguardian/gg-front'

import { dateTimeFormat } from '../../utils/time'
import PingIcon from '../../assets/ping-icon.svg'
import PushIcon from '../../assets/push-icon.svg'

const Login = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > :not(:first-child) {
    margin-left: 20px;
  }
`

const PushEvent = memo(() => (
  <Login>
    <img alt="Push" height="28px" src={PushIcon} />
    <span>Push</span>
  </Login>
))

const PingEvent = memo(() => (
  <Login>
    <img alt="Public" height="28px" src={PingIcon} />
    <span>Real time protection activated</span>
  </Login>
))

const EventTable = ({ events }) => {
  const columns = [
    {
      Cell: ({ value }) => {
        switch (value) {
          case 'push':
            return <PushEvent />
          case 'ping':
            return <PingEvent />
          default:
            return <span>{value}</span>
        }
      },
      Header: 'Type',
      accessor: 'event_type',
      width: 300,
    },
    {
      Cell: ({ value, original }) => (
        <TruncatedText>
          {original.type === 'Commit' ? (
            <Text color="lightPrimary">{value}</Text>
          ) : (
            <StyledLink
              href={`https://github.com/${value}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {value}
            </StyledLink>
          )}
        </TruncatedText>
      ),
      Header: 'Actor',
      accessor: 'user.login',
      minWidth: 200,
    },
    {
      Cell: ({ value }) => (
        <TruncatedText>{format(parseISO(value), dateTimeFormat)}</TruncatedText>
      ),
      Header: 'Date',
      accessor: 'gg_created_at',
      defaultSortDesc: true,
      minWidth: 200,
    },
    {
      Cell: ({ original }) => (
        <TruncatedText>
          <StyledLink
            href={`https://github.com/${original.repository.owner.login}/${original.repository.name}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {original.repository.owner.login}/{original.repository.name}
          </StyledLink>
        </TruncatedText>
      ),
      Header: 'Repository',
      accessor: 'repository_name',
      minWidth: 200,
    },
  ]
  return <ReactTable columns={columns} data={events} />
}

export default EventTable
