//@flow
export const joinSquadRequest = (token: string): JoinSquadRequest => ({
  token,
  type: 'JOIN_SQUAD_REQUEST',
})

export const joinSquadSuccess = (payload: Squad): JoinSquadSuccess => ({
  payload,
  squadId: payload.id,
  type: 'JOIN_SQUAD_SUCCESS',
})

export const joinSquadFailure = (): JoinSquadFailure => ({
  type: 'JOIN_SQUAD_FAILURE',
})

export const createSquadRequest = (
  name: string,
  onCompletion: () => void
): CreateSquadRequest => ({
  name,
  onCompletion,
  type: 'CREATE_SQUAD_REQUEST',
})

export const createSquadSuccess = (payload: Squad): CreateSquadSuccess => ({
  payload,
  squadId: payload.id,
  type: 'CREATE_SQUAD_SUCCESS',
})

export const createSquadFailure = (): CreateSquadFailure => ({
  type: 'CREATE_SQUAD_FAILURE',
})

export const deleteSquadRequest = (squadId: SquadId): DeleteSquadRequest => ({
  squadId,
  type: 'DELETE_SQUAD_REQUEST',
})

export const deleteSquadSuccess = (squadId: SquadId): DeleteSquadSuccess => ({
  squadId,
  type: 'DELETE_SQUAD_SUCCESS',
})

export const deleteSquadFailure = (): DeleteSquadFailure => ({
  type: 'DELETE_SQUAD_FAILURE',
})

export const inviteMemberRequest = (
  squadId: SquadId,
  email: string,
  onCompletion: () => void
): InviteMemberRequest => ({
  email,
  onCompletion,
  squadId,
  type: 'INVITE_SQUAD_MEMBER_REQUEST',
})

export const inviteMemberSuccess = (
  squadId: SquadId,
  payload: SquadToken
): InviteMemberSuccess => ({
  payload,
  squadId,
  type: 'INVITE_SQUAD_MEMBER_SUCCESS',
})

export const inviteMemberFailure = (): InviteMemberFailure => ({
  type: 'INVITE_SQUAD_MEMBER_FAILURE',
})

export const deleteInvitationRequest = (
  squadId: SquadId,
  tokenId: number
): DeleteInvitationRequest => ({
  loadingId: tokenId,
  squadId,
  tokenId,
  type: 'DELETE_INVITATION_REQUEST',
})

export const deleteInvitationSuccess = (
  squadId: SquadId,
  tokenId: number
): DeleteInvitationSuccess => ({
  loadingId: tokenId,
  squadId,
  tokenId,
  type: 'DELETE_INVITATION_SUCCESS',
})

export const deleteInvitationFailure = (): DeleteInvitationFailure => ({
  type: 'DELETE_INVITATION_FAILURE',
})

export const leaveSquadRequest = (
  squadId: SquadId,
  onCompletion: () => void
): LeaveSquadRequest => ({
  onCompletion,
  squadId,
  type: 'LEAVE_SQUAD_REQUEST',
})

export const leaveSquadSuccess = (squadId: SquadId): LeaveSquadSuccess => ({
  squadId,
  type: 'LEAVE_SQUAD_SUCCESS',
})

export const leaveSquadFailure = (): LeaveSquadFailure => ({
  type: 'LEAVE_SQUAD_FAILURE',
})

export const removeMemberRequest = (
  squadId: SquadId,
  memberId: number,
  onCompletion: () => void
): RemoveMemberRequest => ({
  memberId,
  onCompletion,
  squadId,
  type: 'REMOVE_MEMBER_REQUEST',
})

export const removeMemberSuccess = (
  squadId: SquadId,
  memberId: number
): RemoveMemberSuccess => ({
  memberId,
  squadId,
  type: 'REMOVE_MEMBER_SUCCESS',
})

export const removeMemberFailure = (): RemoveMemberFailure => ({
  type: 'REMOVE_MEMBER_FAILURE',
})

export const changeMemberStatusRequest = (
  squadId: SquadId,
  memberId: number,
  status: SquadStatus
): ChangeMemberStatusRequest => ({
  memberId,
  squadId,
  status,
  type: 'CHANGE_MEMBER_STATUS_REQUEST',
})

export const changeMemberStatusSuccess = (
  squadId: SquadId,
  payload: SquadMember
): ChangeMemberStatusSuccess => ({
  payload,
  squadId,
  type: 'CHANGE_MEMBER_STATUS_SUCCESS',
})

export const changeMemberStatusFailure = (): ChangeMemberStatusFailure => ({
  type: 'CHANGE_MEMBER_STATUS_FAILURE',
})

export const transferOwnershipRequest = (
  squadId: SquadId,
  memberId: number,
  onCompletion: () => void
): TransferOwnershipRequest => ({
  memberId,
  onCompletion,
  squadId,
  type: 'TRANSFER_OWNERSHIP_REQUEST',
})

export const transferOwnershipSuccess = (
  payload: Squad
): TransferOwnershipSuccess => ({
  payload,
  squadId: payload.id,
  type: 'TRANSFER_OWNERSHIP_SUCCESS',
})

export const transferOwnershipFailure = (): TransferOwnershipFailure => ({
  type: 'TRANSFER_OWNERSHIP_FAILURE',
})
