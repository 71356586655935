import React, { memo } from 'react'

import 'styled-components/macro'
import { ResponsiveGraph } from '@gitguardian/gg-front'

const HistoryGraph = ({ commits }) => {
  const data = Object.entries(commits).map(([date, commits]) => ({
    commits,
    time: date,
  }))

  return (
    <ResponsiveGraph
      data={data}
      dataConfig={[{ dataKey: 'commits', graphType: 'Area' }]}
      height={200}
      width="100%"
    />
  )
}

export default memo(HistoryGraph)
