export const readCommitsBins = () => ({
  type: 'READ_COMMITS_BINS',
})

export const readCommitsBinsSuccess = (payload, idKey) => ({
  idKey,
  payload,
  type: 'READ_COMMITS_BINS_SUCCESS',
})

export const readAnalysedCommitsCount = () => ({
  type: 'READ_ANALYSED_COMMITS_COUNT',
})

export const readAnalysedCommitsCountSuccess = (payload, idKey) => ({
  idKey,
  payload,
  type: 'READ_ANALYSED_COMMITS_COUNT_SUCCESS',
})
