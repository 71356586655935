function user(state = null, action) {
  switch (action.type) {
    case 'UPDATE_USER_OPTIMISTIC':
    case 'UPDATE_USER_SUCCESS':
    case 'READ_USER_SUCCESS':
      return action.payload

    case 'CREATE_EMAIL_SUCCESS':
      return {
        ...state,
        emails: [...state.emails, action.payload],
      }
    case 'DELETE_EMAIL_SUCCESS': {
      const emailId = action.id
      return {
        ...state,
        emails: state.emails.filter(email => email.id !== emailId),
      }
    }
    case 'RESET_USER':
      return null

    default:
      return state
  }
}

export default user
