import { put, takeEvery } from 'redux-saga/effects'

import { signOut } from '../actions/user'

function* handleError(action) {
  if (action.payload.message === 'Github access revoked Error') {
    yield put(signOut())
  }
}

function* errorsSaga() {
  yield takeEvery('ERROR', handleError)
}

export default errorsSaga
