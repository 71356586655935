// GG SQUADS
export const DEFAULT_MAX_SQUAD_SIZE = 5
export const DEFAULT_MAX_SQUAD_NUMBER = 3
export const SQUAD_TOKEN_EXPIRE_HOURS = 24

export const MAXIMUM_USER_EMAILS = 5

const { REACT_APP_ENABLE_SQUADS, REACT_APP_ENABLE_COMMIT_STATUS } = process.env

export const ENABLE_SQUADS = /true/i.test(REACT_APP_ENABLE_SQUADS)
export const ENABLE_COMMIT_STATUS = /true/i.test(REACT_APP_ENABLE_COMMIT_STATUS)

export const EVENTS_PER_PAGE = 20

export const errorMessages = {
  expired_token: 'The token you used is expired.',
  invalid_token: 'The token you used is invalid.',
}
