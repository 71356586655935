import React, { memo, useState } from 'react'
import 'styled-components/macro'
import { Text, Select, X } from '@gitguardian/gg-front'

import { FileLeak } from './leaks/Leak.component'
import { Wrapper, LeakTitle, Row } from './leaks/Leak.style'

const STATUSES = {
  acknowledged: { type: 'info', value: 'acknowledged' },
  resolved: { type: 'success', value: 'resolved' },
  triggered: { type: 'danger', value: 'triggered' },
}

export const Leak = memo(
  ({ id, commit, secrets, filename, status, updateLeakStatus }) => {
    const [currentStatus, setCurrentStatus] = useState(STATUSES[status])

    return (
      <Row>
        <LeakTitle>
          <X
            css={`
              justify-content: space-between;
            `}
          >
            <Text>Change status of the leak</Text>
            <Select
              css={`
                width: 200px;
              `}
              onChange={newStatus => {
                updateLeakStatus(id, newStatus)
                setCurrentStatus(STATUSES[newStatus])
              }}
              options={Object.keys(STATUSES).map(value => ({
                name: value,
                value,
              }))}
              placeholder={currentStatus.value}
              value={currentStatus.value}
              values={Object.keys(STATUSES)}
            />
          </X>
        </LeakTitle>
        <Wrapper>
          <FileLeak
            commitSha={commit.sha}
            commitUrl={commit.html_url}
            filename={filename}
            secrets={secrets}
            type={currentStatus.type}
          />
        </Wrapper>
      </Row>
    )
  }
)

const EmptyLeak = ({ isPendingMember }) => (
  <div>
    {isPendingMember
      ? "The owner of your squad needs to confirm your membership before you can see your squad's leaks."
      : 'No credentials were found in your commits since setup, you are good to go!'}
  </div>
)

const MemoEmptyLeak = memo(EmptyLeak)

const LeakList = ({ leaks, isPendingMember, updateLeakStatus }) => {
  if (leaks.length) {
    return leaks.map(leak => (
      <Leak {...leak} key={leak.id} updateLeakStatus={updateLeakStatus} />
    ))
  }
  return <MemoEmptyLeak isPendingMember={isPendingMember} />
}

export default LeakList
