import React, { useState, memo, useCallback } from 'react'
import styled from 'styled-components/macro'

import {
  TruncatedText,
  Text,
  X,
  GHStyledLink as StyledLink,
  Table,
  SquadButton,
} from '@gitguardian/gg-front'

import Tooltip from './Tooltip'
import FormModal from './FormModal'

const Info = styled.div`
  position: relative;
  display: flex;
`

const Login = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > :not(:first-child) {
    margin-left: 20px;
  }
`

const getModal = (
  id,
  action,
  closeModal,
  squadId,
  deleteSquad,
  removeMember,
  leaveSquad,
  transferOwnership
) => {
  switch (action) {
    case 'DELETE_MEMBER':
      return {
        onSubmit: () => removeMember(squadId, id, closeModal),
        submitText: 'Remove',
        text: 'Are you sure you want remove this member from the squad?',
        title: 'Remove Member',
      }
    case 'LEAVE_SQUAD':
      return {
        onSubmit: () => leaveSquad(squadId, closeModal),
        submitText: 'Leave',
        text: 'Are you sure you want to leave this squad?',
        title: 'Leave Squad',
      }
    case 'DELETE_SQUAD':
      return {
        onSubmit: () => deleteSquad(squadId, closeModal),
        submitText: 'Delete',
        text: 'Are you sure you want to delete this squad?',
        title: 'Delete Squad',
      }
    case 'PROMOTE_MEMBER':
      return {
        onSubmit: () => transferOwnership(squadId, id, closeModal),
        submitText: 'Promote',
        text: 'Are you sure you want to promote this member as squad owner?',
        title: 'Promote member',
      }
    default:
      throw new Error(`action ${action} unknown`)
  }
}

const Avatar = styled.img`
  border-radius: 50%;
`
const CellWrapper = styled(X)`
  padding: 0 5px;
`
const Divider = styled.div`
  width: 1px;
  background-color: rgba(0, 0, 0, 0.07);
  margin: 0 10px;
  align-self: stretch;
`

const rank = {
  member: 2,
  owner: 1,
  pending: 3,
}
const rankStatus = status => (rank[status] ? rank[status] : 0)
const sortStatus = (a, b) =>
  rankStatus(a.status) > rankStatus(b.status) ? 1 : -1

const MembersTable = ({
  members,
  currentUserId,
  isOwner,
  confirmMember,
  deleteSquad,
  squadId,
  removeMember,
  leaveSquad,
  transferOwnership,
}) => {
  const [modal, setModal] = useState(null)
  const sortedMember = members.sort(sortStatus)

  const columns = [
    {
      Cell: ({ original, value }) => (
        <Login>
          {original.avatar_url && (
            <Avatar alt={value} height="30px" src={original.avatar_url} />
          )}
          <TruncatedText>
            <StyledLink
              href={`https://github.com/${value}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {value}
            </StyledLink>
          </TruncatedText>
        </Login>
      ),
      Header: 'Login',
      accessor: 'login',
      minWidth: 250,
    },
    {
      Cell: ({ value, original }) => {
        const pending = original.status === 'pending' &&
          original.id === currentUserId && (
            <Tooltip
              content={
                <span>
                  Your membership is pending, and the owner needs to confirm
                  your membership for you to be able to see leaks and events
                  related to the squad.
                </span>
              }
            >
              <span>{value}</span>
            </Tooltip>
          )
        return (
          <CellWrapper>
            <Text>{pending || value}</Text>
          </CellWrapper>
        )
      },
      Header: 'Status',
      accessor: 'status',
      minWidth: 250,
    },
    {
      Cell: ({ original }) => {
        const canDeleteOrPromote = isOwner && original.id !== currentUserId
        return (
          <CellWrapper>
            {original.status === 'pending' && isOwner && (
              <Info>
                <Tooltip
                  content={
                    <span>
                      This member is pending, and needs you to confirm its
                      membership to be able to see leaks and events related to
                      the squad.
                    </span>
                  }
                  placement="right"
                >
                  <StyledLink
                    onClick={() => confirmMember(squadId, original.id)}
                  >
                    accept
                  </StyledLink>
                </Tooltip>
              </Info>
            )}
            {canDeleteOrPromote && (
              <>
                {original.status !== 'pending' && (
                  <StyledLink
                    onClick={() =>
                      setModal({ action: 'PROMOTE_MEMBER', id: original.id })
                    }
                  >
                    promote
                  </StyledLink>
                )}
                <Divider />
                <StyledLink
                  color="red"
                  onClick={() =>
                    setModal({ action: 'DELETE_MEMBER', id: original.id })
                  }
                >
                  delete
                </StyledLink>
              </>
            )}
            {!isOwner && original.id === currentUserId && (
              <StyledLink
                color="red"
                onClick={() =>
                  setModal({ action: 'LEAVE_SQUAD', id: original.id })
                }
              >
                leave
              </StyledLink>
            )}
          </CellWrapper>
        )
      },
      Header: 'Actions',
      accessor: 'emails',
      minWidth: 300,
    },
  ]
  const closeModal = useCallback(() => setModal(null), [])
  const modalProps =
    modal != null &&
    getModal(
      modal.id,
      modal.action,
      closeModal,
      squadId,
      deleteSquad,
      removeMember,
      leaveSquad,
      transferOwnership
    )
  return (
    <>
      <Table
        columns={columns}
        data={sortedMember}
        minRows={5}
        noDataText="No developers"
      />
      <div>
        {isOwner && (
          <SquadButton
            css="margin-top:10px;"
            onClick={() =>
              setModal({ action: 'DELETE_SQUAD', id: currentUserId })
            }
          >
            Delete Squad
          </SquadButton>
        )}
      </div>
      {modal != null && (
        <FormModal
          {...modalProps}
          onClose={closeModal}
          onOutsideClick={closeModal}
        />
      )}
    </>
  )
}

export default memo(MembersTable)
