import { ENABLE_SQUADS } from '../../utils/config'

const initialState = { currentViewId: 'user', mode: 'user' }

const views = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_VIEW':
      return { currentViewId: 'user', mode: 'user' }
    case 'SET_SQUAD_VIEW':
      return ENABLE_SQUADS
        ? {
            currentViewId: action.payload.id,
            mode: 'squad',
          }
        : state
    case 'RESET_VIEW':
      return initialState
    default:
      return state
  }
}

export default views
