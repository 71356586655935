import React from 'react'
import * as Sentry from '@sentry/browser'
import { ErrorBoundary as BaseErrorBoundary } from '@gitguardian/gg-front'

export const reportSentry = (error, info) => {
  Sentry.withScope(scope => {
    Object.keys(info).forEach(key => {
      scope.setExtra(key, info[key])
    })
    Sentry.captureException(error)
  })
}

export default function ErrorBoundary({ children, ...args }) {
  return (
    <BaseErrorBoundary {...args} callback={reportSentry}>
      {children}
    </BaseErrorBoundary>
  )
}
