import { put, call, takeEvery, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import enhanceSagaWithOnCompletion from '../../lib/enhanceSagaWithOnCompletion'
import { get, post, _delete, put as httpPut } from '../../lib/xmlHttpRequest'
import {
  readUserSuccess,
  updateUserOptimistic,
  updateUserSuccess,
  resetUser,
  updateUser,
} from '../actions/user'
import { userSelector } from '../selectors/user'
import { deleteEmailSuccess, createEmailSuccess } from '../actions/emails'
import { resetViews } from '../actions/views'

function* readUser() {
  try {
    const response = yield call(get, '/user')

    yield put(readUserSuccess(response))
  } catch (error) {
    // Todo set notification
    yield put(push(`/signin${window.location.search}`))
  }
}

function* updateUserSaga(action) {
  const user = yield select(userSelector)

  const updatedUser = Object.assign({}, user, action.payload)

  yield put(updateUserOptimistic(updatedUser))

  try {
    const response = yield call(httpPut, '/user', action.payload)

    yield put(updateUserSuccess(response))
  } catch (error) {
    // Todo set notifications
  }
}

function* updateUseronboardingStepIndex(action) {
  const { nextonboardingStepIndex, nextUrl } = action.payload

  const payload = {
    onboarding_step_index: nextonboardingStepIndex,
  }

  yield put(updateUser(payload))

  if (nextUrl) {
    yield put(push(nextUrl))
  }
}

function* signOut() {
  try {
    yield call(get, '/auth/signout')

    yield put(resetUser())
    yield put(resetViews())
    yield put(push('/signin'))
  } catch (error) {
    // Todo set notifications
  }
}

function* createEmail(action) {
  try {
    const response = yield call(post, '/user/emails', action.payload)

    yield put(createEmailSuccess(response))
  } catch (error) {
    // Todo set notifications
  }
}

function* updateEmail(action) {
  try {
    yield call(httpPut, `/user/emails/${action.payload.id}`, action.payload)
  } catch (error) {
    // Todo set notifications
  }
}

function* deleteEmail(action) {
  try {
    yield call(_delete, `/user/emails/${action.id}`)
    yield put(deleteEmailSuccess(action.id))
  } catch (error) {
    // Todo set notifications
  }
}

function* deleteAccount() {
  try {
    yield call(_delete, '/user')

    yield put(resetUser())

    yield put(push('/goodbye'))
  } catch (error) {
    // Todo set notifications
  }
}

function* userSaga() {
  yield takeEvery('ONBOARDING_NEXT_STEP', updateUseronboardingStepIndex)
  yield takeEvery('READ_USER', enhanceSagaWithOnCompletion(readUser))
  yield takeEvery('SIGN_OUT', signOut)
  yield takeEvery('UPDATE_USER', enhanceSagaWithOnCompletion(updateUserSaga))
  yield takeEvery(
    'CREATE_EMAIL_REQUEST',
    enhanceSagaWithOnCompletion(createEmail)
  )
  yield takeEvery('UPDATE_EMAIL', enhanceSagaWithOnCompletion(updateEmail))
  yield takeEvery(
    'DELETE_EMAIL_REQUEST',
    enhanceSagaWithOnCompletion(deleteEmail)
  )
  yield takeEvery('DELETE_ACCOUNT', deleteAccount)
}

export default userSaga
