import React, { memo } from 'react'
import 'styled-components/macro'
import { Formik, Form } from 'formik'
import { StyledLink, Text, X, TitledCard, Switch } from '@gitguardian/gg-front'
import { ENABLE_COMMIT_STATUS } from '../../utils/config'

const CheckStatusOption = ({ acceptsStatusPush, updateUser }) => (
  <>
    <Formik
      initialValues={{
        acceptsStatusPush,
      }}
      onSubmit={values => {
        updateUser({
          accepts_status_push: values.acceptsStatusPush,
        })
      }}
      validateOnChange={false}
    >
      {({ values, handleSubmit, setFieldValue, submitForm }) => (
        <Form onSubmit={handleSubmit}>
          <X css="justify-content: space-between">
            <Text as="div">
              <Text>
                We can add a{' '}
                <StyledLink
                  href="https://help.github.com/en/articles/about-status-checks"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  status check
                </StyledLink>{' '}
                for every commit we scan!
              </Text>
            </Text>
            <Switch
              checked={values.acceptsStatusPush}
              onChange={async () => {
                await setFieldValue(
                  'acceptsStatusPush',
                  !values.acceptsStatusPush
                )
                submitForm()
              }}
              type="checkbox"
            />
          </X>
        </Form>
      )}
    </Formik>
  </>
)

const CommitStatus = ({ acceptsStatusPush, updateUser }) =>
  ENABLE_COMMIT_STATUS && (
    <TitledCard gridColumn="1 / -1" title="Commit Status">
      <CheckStatusOption
        acceptsStatusPush={acceptsStatusPush}
        updateUser={updateUser}
      />
    </TitledCard>
  )

export default memo(CommitStatus)
