import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Switch, Route, Link } from 'react-router-dom'
import 'styled-components/macro'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import {
  ModalProvider,
  MinimalistFooter,
  Notifications,
} from '@gitguardian/gg-front'

import history from './history'
import store, { persistor } from './state/store'
import { unregister } from './registerServiceWorker'
import AccountDeletedScene from './scenes/AccountDeletedScene'
import AuthenticationScene from './scenes/AuthenticationScene'
import CollaboratingRepositoriesScene from './scenes/CollaboratingRepositoriesScene'
import DashboardScene from './scenes/DashboardScene'
import InviteScene from './scenes/InviteScene'
import NotFoundScene from './scenes/NotFoundScene'
import OwnedRepositoriesScene from './scenes/OwnedRepositoriesScene'
import SettingsScene from './scenes/SettingsScene'
import SquadScene from './scenes/SquadScene'
import SquadMembersScene from './scenes/SquadMembersScene'
import TwitterShareScene from './scenes/TwitterShareScene'
import APIProvidersScene from './scenes/APIProvidersScene'
import AuthenticationBouncer from './components/AuthenticationBouncer'
import Layout from './components/Layout'
import Menu from './components/Menu'
import SwitchableTheme from './components/SwitchableTheme'
import { reportSentry } from './components/ErrorBoundary'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: `${process.env.REACT_APP_ENV}_front`,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  })
}

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    debug: process.env.REACT_APP_ENV === 'development',
    gaOptions: { cookieDomain: 'auto' },
  })
}

// Adds sign in checks on protected routes
// Redirects to /signin if not siggned in
const authenticated = Component => props => (
  <AuthenticationBouncer>
    <Component {...props} />
  </AuthenticationBouncer>
)

const footerProps = {
  documentationLink:
    'https://github.com/GitGuardian/Getting-Started-With-The-Individual-App',
  links: [
    { link: 'https://blog.gitguardian.com', name: 'Blog' },
    {
      link: '/supported-credentials',
      name: 'Supported Credentials',
    },
    { link: 'https://twitter.com/GitGuardian', name: 'Twitter' },
    { link: 'https://gitguardian.com', name: 'gitguardian.com' },
  ],
}

const withLayout = Component => props => (
  <Layout
    Footer={<MinimalistFooter {...footerProps} />}
    linkComponent={Link}
    Menu={<Menu />}
    onError={reportSentry}
  >
    <Component {...props} />
  </Layout>
)

const routes = (
  <Provider store={store}>
    <SwitchableTheme>
      <PersistGate loading={null} persistor={persistor}>
        <ModalProvider>
          <Notifications left={'300px'} />
          <ConnectedRouter history={history}>
            <Switch>
              <Route component={AuthenticationScene} exact path="/signin" />
              <Route
                component={authenticated(withLayout(DashboardScene))}
                exact
                path="/"
              />
              <Route
                component={authenticated(withLayout(SettingsScene))}
                exact
                path="/settings"
              />
              <Route
                component={authenticated(withLayout(OwnedRepositoriesScene))}
                exact
                path="/owned-repositories"
              />
              <Route
                component={authenticated(
                  withLayout(CollaboratingRepositoriesScene)
                )}
                exact
                path="/collaborating-repositories"
              />
              <Route
                component={authenticated(withLayout(SquadScene))}
                exact
                path="/squad"
              />
              <Route
                component={authenticated(withLayout(SquadMembersScene))}
                exact
                path="/squad-members"
              />
              <Route
                component={authenticated(withLayout(APIProvidersScene))}
                exact
                path="/supported-credentials"
              />
              <Route
                component={authenticated(withLayout(TwitterShareScene))}
                exact
                path="/share-twitter"
              />
              <Route
                component={authenticated(withLayout(InviteScene))}
                exact
                path="/invite"
              />
              <Route component={AccountDeletedScene} exact path="/goodbye" />
              <Route component={authenticated(withLayout(NotFoundScene))} />
            </Switch>
          </ConnectedRouter>
        </ModalProvider>
      </PersistGate>
    </SwitchableTheme>
  </Provider>
)

ReactDOM.render(routes, document.getElementById('root'))

unregister()
