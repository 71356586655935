import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import 'styled-components/macro'
import {
  Loader,
  Center,
  Text,
  Title,
  FlatterCard as Card,
} from '@gitguardian/gg-front'

import ErrorBoundary from '../../components/ErrorBoundary'
import useFilter from '../../hooks/useFilter'

import { Wrapper } from '../../components/PageUtils'
import RepositoriesTable from '../../components/RepositoriesTable'
import Search from '../../components/Search'

const accessorByName = {
  search: ['name'],
  select: 'type',
  type: 'name',
}

const CollaboratingRepositories = ({
  readAllScanResults,
  readRepositories,
  repositories,
  viewMode,
}) => {
  const [workingRepositories, setOptions] = useFilter(
    repositories.not_owned,
    accessorByName
  )

  const loading = !repositories.fetched

  useEffect(() => {
    if (loading) {
      readRepositories()
    } else {
      readAllScanResults('all')
    }
  }, [readAllScanResults, readRepositories, loading])

  return viewMode === 'squad' ? (
    <Redirect to="/" />
  ) : (
    <Wrapper>
      <Title>Collaborating repositories</Title>

      {!loading && (
        <>
          <Text
            css="
              grid-column: 1 / -1;"
          >
            These are the public repositories you don&#39;t own but collaborate
            on with fellow developers.
            <br /> Still, you can scan them. Invite the owner to setup real-time
            monitoring!
          </Text>
          <Card
            css={`
              grid-column: 1 / -1;
              justify-content: space-between;
            `}
          >
            <Search setOptions={setOptions} />
          </Card>
        </>
      )}

      {loading ? (
        <Center
          css={`
            flex-grow: 1;
          `}
        >
          <Loader />
        </Center>
      ) : (
        <ErrorBoundary>
          <RepositoriesTable
            isOwner={false}
            repos={workingRepositories}
            scannable={() => true}
          />
        </ErrorBoundary>
      )}
    </Wrapper>
  )
}

export default CollaboratingRepositories
