import { connect } from 'react-redux'
import withSizes from 'react-sizes'

import {
  readRepositories,
  scanRepository,
  readAllScanResultsRequest,
  scanAllRepositories,
} from '../../state/actions/repositories'

import { breakpoint } from '../../theme'
import { viewModeSelector } from '../../state/selectors/views'
import { isOnboardingSelector } from '../../state/selectors/onboarding'

import OwnedRepositories from './OwnedRepositories.component'

const mapStateToProps = state => ({
  isOnboarding: isOnboardingSelector(state),
  repositories: state.repositories,
  scanStatuses: state.scanStatuses,
  viewMode: viewModeSelector(state),
})

const mapDispatchToProps = {
  readAllScanResults: readAllScanResultsRequest,
  readRepositories,
  scanAllRepositories,
  scanRepository,
}

const mapSizesToProps = ({ width }) => ({
  showIllustration: width > breakpoint.Ldigit,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(OwnedRepositories))
