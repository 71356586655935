import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import 'styled-components/macro'

import OnboardingTracker from '../OnboardingTracker'
import { ReactComponent as DashboardIcon } from '../../assets/dashboard_icon.svg'
import { ReactComponent as SettingsIcon } from '../../assets/settings_icon.svg'
import { ReactComponent as OwnedReposIcon } from '../../assets/owned_repos_icon.svg'
import { ReactComponent as CollabReposIcon } from '../../assets/collab_repos_icon.svg'
import { ReactComponent as HelpIcon } from '../../assets/help_icon.svg'
import { ReactComponent as SquadLogo } from '../../assets/squad_logo.svg'
import { ReactComponent as SignOutIcon } from '../../assets/signout_icon.svg'

import { ENABLE_SQUADS } from '../../utils/config'
import ViewSwitcher from '../ViewSwitcher'

import { Nav, Wrapper, BottomLinks } from './Menu.style'

const Block = ({
  path,
  redirect,
  title,
  Icon,
  className,
  as = Link,
  Item = Nav.Block,
  href,
}) => {
  const selected = path === redirect
  const props = as === 'a' && { rel: 'noopener noreferrer', target: '_blank' }
  return (
    <Nav.Link as={as} href={href} selected={selected} to={redirect} {...props}>
      <Item className={className} selected={selected}>
        {Icon && (
          <Nav.Icon>
            <Icon />
          </Nav.Icon>
        )}
        {title}
      </Item>
    </Nav.Link>
  )
}

const Menu = ({ user, location, signOut, viewMode }) => {
  const path = location.pathname
  const onboardingIsOver = user && user.onboarding_step_index === -1
  if (!onboardingIsOver) {
    return (
      <Wrapper>
        <ViewSwitcher />
        <OnboardingTracker location={location} />
      </Wrapper>
    )
  }

  const ViewMenu = viewMode === 'user' && (
    <>
      <Block
        Icon={OwnedReposIcon}
        path={path}
        redirect="/owned-repositories"
        title="Owned repos"
      />
      <Block
        Icon={CollabReposIcon}
        path={path}
        redirect="/collaborating-repositories"
        title="Collaborating repos"
      />
      {ENABLE_SQUADS && (
        <Block Icon={SquadLogo} path={path} redirect="/squad" title="Squad" />
      )}
    </>
  )

  return (
    <Wrapper>
      <ViewSwitcher />

      <Nav>
        <Block
          Icon={DashboardIcon}
          path={path}
          redirect="/"
          title="Dashboard"
        />
        <Block
          Icon={SettingsIcon}
          path={path}
          redirect="/settings"
          title="Settings"
        />

        {ViewMenu}

        <BottomLinks>
          <Nav.Link as="div" onClick={signOut}>
            <Nav.Item>
              <Nav.Icon>
                <SignOutIcon />
              </Nav.Icon>
              Log out
            </Nav.Item>
          </Nav.Link>
          <Block
            as="a"
            href="https://github.com/GitGuardian/Getting-Started-With-The-Individual-App"
            Icon={HelpIcon}
            Item={Nav.Item}
            path={path}
            title="Help"
          />
          <Block
            css={`
              padding-left: 0;
              justify-content: center;
            `}
            Item={Nav.Item}
            path={path}
            redirect="/share-twitter"
            title="Share on Twitter"
          />
        </BottomLinks>
      </Nav>
    </Wrapper>
  )
}

export default memo(Menu)
