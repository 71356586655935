import { connect } from 'react-redux'

import { scanRepository } from '../../state/actions/repositories'

import RepositoriesTable from './RepositoriesTable.component'

const mapStateToProps = ({ scanStatuses }) => ({
  scanStatuses,
})

const mapDispatchToProps = {
  scanRepository,
}

export default connect(mapStateToProps, mapDispatchToProps)(RepositoriesTable)
