import { connect } from 'react-redux'

import { readTweets } from '../../state/actions/tweets'
import { viewModeSelector } from '../../state/selectors/views'

import TwitterShare from './TwitterShare.component'

const mapStateToProps = state => ({
  tweets: state.tweets,
  viewMode: viewModeSelector(state),
})

const mapDispatchToProps = {
  readTweets,
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitterShare)
