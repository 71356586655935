import React, { useState, useEffect, useMemo, useRef } from 'react'
import 'styled-components/macro'
import { Arrow, Y, useOnClickOutside } from '@gitguardian/gg-front'

import { ReactComponent as SquadLogo } from '../../assets/squad_logo.svg'
import { ReactComponent as UserLogo } from '../../assets/owned_repos_icon.svg'
import CreateSquadModal from '../CreateSquadModal'
import { DEFAULT_MAX_SQUAD_NUMBER, ENABLE_SQUADS } from '../../utils/config'

import {
  Wrapper,
  Img,
  Info,
  Login,
  Dropdown,
  Base,
  ContentWrapper,
  Plus,
} from './ViewSwitcher.style'

const ViewSwitcher = ({
  user,
  currentView,
  views,
  viewMode,
  setUserView,
  setSquadView,
  createSquad,
}) => {
  const [toggled, setToggled] = useState(false)
  const [modal, setModal] = useState(false)
  const baseRef = useRef(null)

  const currentViewName =
    viewMode === 'user'
      ? currentView.name || currentView.login
      : currentView.name

  const max_squad_number = user.max_squad_number || DEFAULT_MAX_SQUAD_NUMBER
  const memoizedSquadNames = useMemo(
    () =>
      Object.values(views)
        .filter(view => view.mode === 'squad')
        .map(squad => squad.name),
    [views]
  )
  const ref = useOnClickOutside(() => {
    setToggled(false)
  }, [baseRef])

  useEffect(() => setToggled(false), [currentView])

  return (
    <Wrapper>
      <Base onClick={() => setToggled(!toggled)} ref={baseRef}>
        <>
          {/* TODO have a proper image for squads */}
          <Img alt="user" src={user.avatar_url} />
          <Y
            css={`
              justify-content: space-between;
              align-items: flex-start;
            `}
          >
            <Info>
              {viewMode === 'user' ? 'User' : 'Squad'}
              {ENABLE_SQUADS && (
                <Arrow
                  css={`
                    margin-left: 10px;
                  `}
                  direction={toggled ? 'up' : 'down'}
                />
              )}
            </Info>
            <Login
              color={viewMode === 'user' ? 'darkPrimary' : 'white'}
              title={currentViewName}
            >
              {currentViewName}
            </Login>
          </Y>
        </>
      </Base>
      {ENABLE_SQUADS && toggled && (
        <Dropdown ref={ref}>
          {Object.entries(views)
            // Each entry is a pair, so [, { id }] is the id of the entry value
            .filter(([, { id }]) => id !== currentView.id)
            .map(([viewKey, view]) => (
              <Dropdown.Item
                key={view.id}
                onClick={() =>
                  viewKey === 'user' ? setUserView() : setSquadView(view.id)
                }
              >
                <ContentWrapper>
                  {viewKey === 'user' ? (
                    <UserLogo
                      css={`
                        margin: 5px;
                      `}
                      height="20px"
                      width="20px"
                    />
                  ) : (
                    <SquadLogo
                      css={`
                        margin: 5px;
                      `}
                      height="25px"
                      width="25px"
                    />
                  )}

                  <Dropdown.Content title={view.name || view.login}>
                    {view.name || view.login}
                  </Dropdown.Content>
                </ContentWrapper>
              </Dropdown.Item>
            ))}
          {Object.keys(views).length < max_squad_number + 1 && (
            <Dropdown.Link key="add_squad" onClick={() => setModal(!modal)}>
              <ContentWrapper>
                <Plus />
                Add Squad
              </ContentWrapper>
            </Dropdown.Link>
          )}
        </Dropdown>
      )}
      {modal && (
        <CreateSquadModal
          createSquad={createSquad}
          existingSquads={memoizedSquadNames}
          setModal={setModal}
        />
      )}
    </Wrapper>
  )
}

export default ViewSwitcher
