//@flow

// $FlowFixMe
const deleteKey = (key, { [key]: _, ...newObj }) => newObj

const squad = (state: Squad, action: SquadAction): Squad => {
  switch (action.type) {
    case 'INVITE_SQUAD_MEMBER_SUCCESS':
      return {
        ...state,
        tokens: [...state.tokens, action.payload],
      }
    case 'DELETE_INVITATION_SUCCESS': {
      const { tokenId } = action
      return {
        ...state,
        tokens: state.tokens.filter(token => token.id !== tokenId),
      }
    }
    case 'CHANGE_MEMBER_STATUS_SUCCESS': {
      const updatedMember: SquadMember = action.payload
      return {
        ...state,
        members: state.members.map(member =>
          member.id === updatedMember.id ? updatedMember : member
        ),
      }
    }
    case 'REMOVE_MEMBER_SUCCESS': {
      const { memberId } = action
      return {
        ...state,
        members: state.members.filter(member => member.id !== memberId),
      }
    }
    case 'TRANSFER_OWNERSHIP_SUCCESS':
    case 'CREATE_SQUAD_SUCCESS':
    case 'JOIN_SQUAD_SUCCESS':
    case 'READ_SQUAD_SUCCESS':
      return action.payload

    default:
      return state
  }
}

const squads = (state: ?SquadsState = null, action: SquadsAction) => {
  switch (action.type) {
    case 'CREATE_SQUAD_SUCCESS':
    case 'INVITE_SQUAD_MEMBER_SUCCESS':
    case 'DELETE_INVITATION_SUCCESS':
    case 'REMOVE_MEMBER_SUCCESS':
    case 'TRANSFER_OWNERSHIP_SUCCESS':
    case 'JOIN_SQUAD_SUCCESS':
    case 'CHANGE_MEMBER_STATUS_SUCCESS':
    case 'READ_SQUAD_SUCCESS': {
      const squadState = state == null ? {} : state[action.squadId]
      return {
        ...state,
        [action.squadId]: squad(squadState, action),
      }
    }
    case 'DELETE_SQUAD_SUCCESS':
    case 'LEAVE_SQUAD_SUCCESS':
      return deleteKey(action.squadId, state)

    case 'READ_SQUADS_SUCCESS':
      return action.payload.reduce((acc, squad) => {
        acc[squad.id] = squad
        return acc
      }, {})

    default:
      return state
  }
}

export default squads
