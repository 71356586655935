import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Spinner, Center } from '@gitguardian/gg-front'
import { getQueryParam } from '../../utils/utils'

const Invite = ({ location, loadingState, joinSquad }) => {
  const tokenArg = getQueryParam(location.search, 'squad_token')
  const token = tokenArg ? tokenArg[1] : null
  useEffect(() => joinSquad(token), [joinSquad, token])
  return (
    <Center>
      {loadingState === 'REQUEST' ? <Spinner /> : <Redirect to="/" />}
    </Center>
  )
}

export default Invite
