import ReactGA from 'react-ga'

const GAMiddleware = () => next => action => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      ReactGA.pageview(action.payload.location.pathname)
      break
    default:
  }
  next(action)
}

export default GAMiddleware
