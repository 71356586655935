import React from 'react'
import styled from 'styled-components/macro'

import { FilterSearch, FilterOptions } from '@gitguardian/gg-front'

import { ReactComponent as BaseSearchLogo } from '../assets/search.svg'

const SearchLogo = styled(BaseSearchLogo)`
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  margin-right: 15px;
  height: 15px;
`

const Wrapper = styled.div`
  position: relative;
  max-width: 250px;
`

const Search = ({ setOptions }) => (
  <FilterOptions onChange={setOptions}>
    <Wrapper>
      <FilterSearch name="search" placeholder="Search repositories" />
      <SearchLogo />
    </Wrapper>
  </FilterOptions>
)

export default Search
