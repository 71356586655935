export const readLeaks = () => ({ type: 'READ_LEAKS_REQUEST' })

export const readLeaksSuccess = payload => ({
  payload,
  type: 'READ_LEAKS_SUCCESS',
})

export const readLeaksFailure = () => ({
  type: 'READ_LEAKS_FAILURE',
})

export const updateLeakStatusRequest = (leakId, status) => ({
  leakId,
  status,
  type: 'UPDATE_LEAK_STATUS_REQUEST',
})

export const updateLeakStatusSuccess = payload => ({
  payload,
  type: 'UPDATE_LEAK_STATUS_SUCCESS',
})

export const updateLeakStatusFailure = () => ({
  type: 'UPDATE_LEAK_STATUS_FAILURE',
})
