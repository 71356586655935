import styled from 'styled-components/macro'

import { ReactComponent as BaseOneSentinel } from '../../assets/one-sentinel.svg'
import { ReactComponent as BaseFourSentinels } from '../../assets/four-sentinels.svg'

export const CreateSquadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 55px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoint.XS}) {
    margin: 30px 0 0;
    flex-direction: column;
    align-items: center;
    > :not(:first-child) {
      margin-top: 20px;
    }
  }
`

export const OneSentinel = styled(BaseOneSentinel)`
  color: ${({ theme }) => theme.palette.squadSecondary};
  width: 60px;
  height: auto;
  margin-top: 10px;
`

export const FourSentinels = styled(BaseFourSentinels)`
  color: ${({ theme }) => theme.palette.squadPrimary};
  width: 60px;
  height: auto;
  margin-top: 10px;
`

export const Screenshot = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 480px;
  border-radius: 6px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
`

export const Mission = styled.div`
  padding: 50px;
`

Mission.Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`
Mission.Row.Spaced = styled(Mission.Row)`
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoint.L}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
      margin-top: 30px;
    }
  }
`

Mission.Row.Centered = styled(Mission.Row)`
  justify-content: center;
  margin-top: 30px;
  > svg {
    margin: 0 30px;
  }
`

Mission.Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

Mission.Column.Part = styled.div`
  display: flex;
  > svg {
    align-self: flex-start;
  }

  > div {
    margin-left: 30px;
  }
`

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background};
  margin: ${({ margin = '30px 0' }) => margin};
`
