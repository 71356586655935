import { put, call, takeEvery } from 'redux-saga/effects'

import enhanceSagaWithOnCompletion from '../../lib/enhanceSagaWithOnCompletion'
import { get } from '../../lib/xmlHttpRequest'
import { readTweetsSuccess } from '../actions/tweets'

function* readTweets() {
  try {
    const response = yield call(get, '/twitter/mentions')

    yield put(readTweetsSuccess(response))
  } catch (error) {
    // Todo set notifications
  }
}

function* tweetsSaga() {
  yield takeEvery('READ_TWEETS', enhanceSagaWithOnCompletion(readTweets))
}

export default tweetsSaga
