import { put, call, select } from 'redux-saga/effects'

import { get } from '../../lib/xmlHttpRequest'
import { currentViewIdSelector } from '../selectors/views'

// The endpoint must start by a '/' if not empty, like in the backend.
export const getUrl = (prefix, endpoint, idKey) =>
  idKey === 'user'
    ? `${prefix}${endpoint}`
    : `${prefix}/squads/${idKey}${endpoint}`

export function* callViewEndpoint(
  urlPrefix,
  endpoint,
  successActionGenerator,
  failureActionGenerator
) {
  try {
    const idKey = yield select(currentViewIdSelector)

    const url = getUrl(urlPrefix, endpoint, idKey)
    const response = yield call(get, url)

    yield put(successActionGenerator(response, idKey))
  } catch (error) {
    if (failureActionGenerator !== undefined) {
      yield put(failureActionGenerator())
    } else {
      yield put({ payload: error, type: 'ERROR' })
    }
  }
}
