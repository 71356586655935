import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '@gitguardian/gg-front'
import {
  Formik,
  Form,
  Field as BaseField,
  ErrorMessage as BaseErrorMessage,
} from 'formik'

import FormModal from './FormModal'

export const Field = styled(BaseField)`
  ${({ theme }) => theme.mixin.card};
`
export const ErrorMessage = styled(BaseErrorMessage)`
  grid-column: 2 / span 3;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.red};
`
const validateName = name =>
  RegExp('^([A-Za-z0-9_\\- ]){4,100}$').test(name.trim())

const validateSquadNameForm = existingSquads => values => {
  const errors = {}
  if (!values.squadName) {
    errors.squadName = 'Required'
  } else if (!validateName(values.squadName)) {
    errors.squadName = 'Invalid name.'
  } else if (existingSquads.some(({ name }) => name === values.squadName)) {
    errors.squadName = 'Email already saved'
  }
  return errors
}

const CreateSquadModal = ({ createSquad, setModal, existingSquads }) => (
  <Formik
    initialValues={{ squadName: '' }}
    onSubmit={values =>
      createSquad(values.squadName.trim(), () => setModal(false))
    }
    validate={validateSquadNameForm(existingSquads)}
  >
    {({ handleSubmit, submitForm, isSubmitting, values }) => (
      <FormModal
        disabled={isSubmitting || values.squadName === ''}
        onClose={() => setModal(false)}
        onOutsideClick={() => setModal(false)}
        onSubmit={submitForm}
        submitText={isSubmitting ? 'Creating...' : 'Create'}
        title="Create New Squad"
      >
        <Text>Name must be at least 4 characters longs,</Text>
        <Text>
          {' '}
          and contain only alphanumeric, spaces, dashes and underscores.
        </Text>
        <Form css="margin-top: 20px;" onSubmit={handleSubmit}>
          <Field name="squadName" placeholder="enter a name" />

          {values.squadName !== '' && (
            <ErrorMessage component="div" name="squadName" />
          )}
        </Form>
      </FormModal>
    )}
  </Formik>
)

export default CreateSquadModal
