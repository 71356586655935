import withSizes from 'react-sizes'

import Layout from './Layout.style'

import { breakpoint } from '../../theme'

const mapSizesToProps = ({ width }) => ({
  collapseMenu: width <= breakpoint.Mdigit,
})

export default withSizes(mapSizesToProps)(Layout)
