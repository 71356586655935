import { connect } from 'react-redux'

import { userMaxSquadNumberSelector } from '../../state/selectors/user'
import { viewsSelector, viewModeSelector } from '../../state/selectors/views'
import { createSquadRequest } from '../../state/actions/squads'

import Squad from './Squad.component'

const mapStateToProps = state => ({
  maxSquadNumber: userMaxSquadNumberSelector(state),
  viewMode: viewModeSelector(state),
  views: viewsSelector(state),
})

const mapDispatchToProps = {
  createSquad: createSquadRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Squad)
