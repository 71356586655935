import React, { Fragment, memo } from 'react'
import { GHStyledLink as StyledLink, Text } from '@gitguardian/gg-front'

import 'styled-components/macro'
import { File, Lead, Match } from './Leak.style'

export const FileLeak = memo(
  ({ secrets, filename, commitUrl, commitSha, type }) => (
    <>
      <Lead>
        {`File ${filename} in commit `}
        <StyledLink href={commitUrl} rel="noopener noreferrer" target="_blank">
          {commitSha}
        </StyledLink>
      </Lead>
      {secrets.map(({ matches, company_name }, j) => (
        <Fragment key={j}>
          <Text>credentials from {company_name}</Text>
          {matches.map(({ name, string_matched }, i) => (
            <Match key={i} type={type}>{`${name} : ${string_matched}`}</Match>
          ))}
        </Fragment>
      ))}
    </>
  )
)

export const CommitLeak = ({ fileLeaks, commitUrl, commitSha, type }) => {
  return fileLeaks.map(({ filename, secrets }, j) => (
    <File key={j}>
      <FileLeak
        commitSha={commitSha}
        commitUrl={commitUrl}
        filename={filename}
        secrets={secrets}
        type={type}
      />
    </File>
  ))
}
