import { put, call, takeEvery } from 'redux-saga/effects'

import {
  readLeaksSuccess,
  readLeaksFailure,
  updateLeakStatusSuccess,
  updateLeakStatusFailure,
} from '../actions/leaks'
import { patch } from '../../lib/xmlHttpRequest'

import { callViewEndpoint } from './utils'

const urlPrefix = '/leaks'

function* readLeaks() {
  yield call(
    callViewEndpoint,
    urlPrefix,
    '',
    readLeaksSuccess,
    readLeaksFailure
  )
}
function* updateLeakStatus({ leakId, status }) {
  try {
    const response: SquadMember = yield call(patch, `${urlPrefix}/${leakId}`, {
      status,
    })
    yield put(updateLeakStatusSuccess(response))
  } catch (error) {
    yield put(updateLeakStatusFailure())
  }
}

function* leaksSaga() {
  yield takeEvery('READ_LEAKS_REQUEST', readLeaks)
  yield takeEvery('UPDATE_LEAK_STATUS_REQUEST', updateLeakStatus)
}

export default leaksSaga
