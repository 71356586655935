import styled from 'styled-components/macro'
import Masonry from 'react-masonry-component'
import { Tweet as BaseTweet, Button, SquadButton } from '@gitguardian/gg-front'

export const TweetsGrid = styled(Masonry)`
  grid-column: 1 / -1;
  margin: 0 auto;
`

export const Tweet = styled(BaseTweet)`
  height: max-content;
  height: intrinsic;
  max-width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
  width: 520px;

  @media (max-width: ${({ theme }) => theme.breakpoint.XXS}) {
    width: 100%;
    padding: 50px 15px;
  }
`

export const TweetWrapper = styled.div`
  display: inline-block;
  margin-bottom: 25px;
`

export const WriteTweet = styled.textarea`
  margin-top: 30px;
  height: 120px;
  width: 520px;
  max-width: 100%;

  color: ${({ theme }) => theme.palette.text};
  ${({ theme }) => theme.typography.text};

  ${({ theme }) => theme.mixin.card};
`

export const WriteTweetWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;

  ${SquadButton}, ${Button} {
    flex: 0;
    height: 100%;
    align-self: flex-end;
    margin-left: 30px;
  }
`
