import { connect } from 'react-redux'

import { viewModeSelector } from '../../state/selectors/views'

import SwitchableTheme from './SwitchableTheme.component'

const mapStateToProps = state => ({
  viewMode: viewModeSelector(state),
})

export default connect(mapStateToProps)(SwitchableTheme)
