export const readSquadSuccess = payload => ({
  payload,
  squadId: payload.id,
  type: 'READ_SQUAD_SUCCESS',
})

export const readSquads = () => ({
  type: 'READ_SQUADS_REQUEST',
})

export const readSquadsSuccess = payload => ({
  payload,
  type: 'READ_SQUADS_SUCCESS',
})

export const setUserView = () => ({
  type: 'SET_USER_VIEW',
})

export const setSquadView = id => ({
  payload: { id },
  type: 'SET_SQUAD_VIEW',
})

export const resetViews = () => ({
  type: 'RESET_VIEW',
})
