import React, { memo } from 'react'
import 'styled-components/macro'

import {
  Subtitle,
  Modal,
  Button,
  SquadButton,
  Text,
} from '@gitguardian/gg-front'

import { Title, Footer, Times } from './FormModal.style'

const FormModal = ({
  children,
  onOutsideClick,
  onClose,
  title,
  disabled = false,
  onSubmit,
  submitText,
  viewMode,
  text,
}) => {
  const StyledButton = viewMode === 'user' ? Button : SquadButton

  return (
    <Modal onOutsideClick={onOutsideClick}>
      <Title>
        <Subtitle>{title}</Subtitle>
        <Times height="20px" onClick={onClose} />
      </Title>
      <div
        css={`
          word-break: break-all;
        `}
      >
        {children || <Text>{text}</Text>}
      </div>
      <Footer>
        <StyledButton disabled={disabled} onClick={onSubmit}>
          {submitText}
        </StyledButton>
      </Footer>
    </Modal>
  )
}

export default memo(FormModal)
