//@flow
import React, { useState, memo } from 'react'
import 'styled-components/macro'
import { validate as validateEmail } from 'email-validator'
import { Formik, Form } from 'formik'
import { X, Button, SquadButton, Title, Text } from '@gitguardian/gg-front'

import {
  MAXIMUM_USER_EMAILS,
  SQUAD_TOKEN_EXPIRE_HOURS,
} from '../../utils/config'
import FormModal from '../../components/FormModal'
import MembersTable from '../../components/MembersTable'
import { Wrapper } from '../../components/PageUtils'

import { Field, ErrorMessage, TitledCard } from './Settings.style'

import CommitStatus from './CommitStatus'
import EmailsTable from './EmailsTable'

const getPrimary = emails => {
  const email = emails.find(email => email.primary)
  return email !== undefined ? email.email : undefined
}
const sortEmails = emails =>
  emails.sort((a, b) => (a.gg_created_at < b.gg_created_at ? -1 : 1))

const PrimaryEmail = memo(({ emails }) => (
  <TitledCard gridColumn="1 / -1" title="Primary email">
    <Text>To receive information related to GitGuardian and alerts.</Text>
    <Field as="input" readOnly value={getPrimary(emails)} />
  </TitledCard>
))

const Settings = ({
  user,
  deleteEmail,
  createEmail,
  currentView,
  isOwner,
  deleteSquad,
  deleteLoading,
  removeMember,
  inviteMember,
  deleteInvitation,
  leaveSquad,
  confirmMember,
  readUser,
  updateUser,
  transferOwnership,
  setUserView,
}: SettingsProps) => {
  const [modal, setModal] = useState(false)

  if (currentView.mode === 'squad' && currentView.id == null) {
    readUser()
    setUserView()
    return null
  }

  const onDelete =
    currentView.mode === 'user'
      ? deleteEmail
      : id => deleteInvitation(currentView.id, id)

  const emails =
    currentView.mode === 'user'
      ? sortEmails(user.emails)
      : sortEmails(currentView.tokens)

  const MAX_EMAILS =
    currentView.mode === 'user'
      ? MAXIMUM_USER_EMAILS
      : currentView.max_size - currentView.members.length

  const validateEmailForm = values => {
    const errors = {}
    if (!validateEmail(values.email)) {
      errors.email = 'Invalid email address'
    } else if (emails.map(email => email.email).includes(values.email)) {
      errors.email = 'Email already saved'
    }
    return errors
  }

  const handleCreateEmail = (email, callback) => {
    const actionGenerator =
      currentView.mode === 'user' ? createEmail : inviteMember
    actionGenerator(currentView.id, email, () => {
      callback()
    })
  }
  const emailsOrMembersTitle =
    currentView.mode === 'user'
      ? {
          subtitle: `These will be used for alerting purposes only (max: ${MAX_EMAILS}).`,
          title: 'Alerting emails',
        }
      : {
          subtitle: isOwner
            ? `We'll send an invitation link to these addresses to allow them to join your squad. (Squads are limited to ${currentView.max_size} members). The link is valid for ${SQUAD_TOKEN_EXPIRE_HOURS} hours.`
            : `Your squad owner can invite up to ${currentView.max_size -
                1} members to his squad`,
          title: 'Invite new members',
        }

  const StyledButton = currentView.mode === 'user' ? Button : SquadButton

  return (
    <Wrapper>
      <Title>Settings</Title>
      <Text css="grid-column: 1 / -1;">
        {currentView.mode === 'user'
          ? 'The more emails you add, the better!'
          : 'You can manage your squad settings here'}
      </Text>
      {currentView.mode === 'user' ? (
        <>
          <PrimaryEmail emails={emails} />
          <CommitStatus
            acceptsStatusPush={currentView.accepts_status_push}
            updateUser={updateUser}
          />
        </>
      ) : (
        <TitledCard gridColumn="1 / -1" title="Squad members">
          <Text>You can manage your squad settings here</Text>
          <MembersTable
            confirmMember={confirmMember}
            currentUserId={user.id}
            deleteSquad={deleteSquad}
            isOwner={isOwner}
            leaveSquad={leaveSquad}
            members={currentView.members}
            removeMember={removeMember}
            squadId={currentView.id}
            transferOwnership={transferOwnership}
            viewMode={currentView}
          />

          {isOwner && modal && (
            <FormModal
              onClose={() => setModal(false)}
              onOutsideClick={() => setModal(false)}
              onSubmit={() => deleteSquad(currentView.id)}
              submitText="Delete"
              text="Are you sure you want to delete this squad?"
              title="Delete Squad"
            />
          )}
        </TitledCard>
      )}
      <TitledCard gridColumn="1 / -1" title={emailsOrMembersTitle.title}>
        <Text>{emailsOrMembersTitle.subtitle}</Text>
        <EmailsTable
          canDelete={currentView === 'user' || isOwner}
          deleteLoading={deleteLoading}
          emails={emails.filter(email => !email.primary)}
          onDelete={onDelete}
          view={currentView}
        />
        {isOwner && emails.length < MAX_EMAILS && (
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, { setFieldValue, setSubmitting }) =>
              handleCreateEmail(values.email, () => {
                setFieldValue('email', '')
                setSubmitting(false)
              })
            }
            validate={validateEmailForm}
            validateOnChange={false}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <X>
                  <Field
                    css="margin-right: 30px; flex: 1;"
                    name="email"
                    placeholder="name@company.com"
                    type="email"
                  />
                  <StyledButton
                    disabled={isSubmitting || values.email === ''}
                    type="submit"
                  >
                    {isSubmitting ? 'Adding...' : 'Add'}
                  </StyledButton>
                </X>
                {values.email !== '' && (
                  <ErrorMessage component="div" name="email" />
                )}
              </Form>
            )}
          </Formik>
        )}
      </TitledCard>
    </Wrapper>
  )
}

type SettingsProps = {
  user: User,
  deleteEmail: (id: number) => DeleteEmailRequest,
  createEmail: (
    userId: number,
    email: string,
    onCompletion: () => void
  ) => CreateEmailRequest,
  showIllustration: boolean,
  isUserMode: boolean,
  currentView: View,
  isOwner: boolean,
  deleteSquad: (squadId: SquadId) => DeleteSquadRequest,
  deleteLoading: ?{ [key: number]: 'REQUEST' | 'SUCCESS' | 'FAILURE' },
  removeMember: (squadId: number, memberId: number) => RemoveMemberRequest,
  inviteMember: (
    squadId: number,
    email: string,
    onCompletion: () => void
  ) => InviteMemberRequest,
  deleteInvitation: (
    squadId: number,
    tokenId: number
  ) => DeleteInvitationRequest,
  leaveSquad: (squadId: SquadId) => LeaveSquadRequest,
  confirmMember: (
    squadId: SquadId,
    memberId: UserId
  ) => ChangeMemberStatusRequest,
  updateUser: (payload: { accepts_status_push: boolean }) => {
    payload: { accepts_status_push: boolean },
    type: 'UPDATE_USER',
  },
}

export default Settings
