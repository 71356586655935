import styled from 'styled-components/macro'

const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-auto-flow: dense;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 28px;

  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.S}) {
    padding: 50px 0;
  }
`

export default Grid
