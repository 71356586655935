export const readEvents = () => ({ type: 'READ_EVENTS_REQUEST' })

export const readEventsSuccess = payload => ({
  payload,
  type: 'READ_EVENTS_SUCCESS',
})

export const readEventsFailure = () => ({
  type: 'READ_EVENTS_FAILURE',
})
