/* eslint-disable sort-keys */

import { css, keyframes } from 'styled-components/macro'

const pulse = keyframes`
    to {
      box-shadow: 0 0 0 10px rgba(72, 66, 183, 0);
    }
  }`

export const mixin = {
  card: css`
    padding: 10px 20px;

    border: 2px solid ${({ theme }) => theme.palette.border};
    border-radius: 6px;
  `,
  pulse: css`
    box-shadow: 0 0 0 0 rgba(72, 66, 183, 0.7);
    animation: ${pulse} 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  `,
  truncateText: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
}

export const breakpoint = {
  XXS: '450px',
  XS: '650px',
  S: '1000px',
  M: '1350px',
  L: '1600px',
  XXSdigit: 450,
  XSdigit: 650,
  Sdigit: 1000,
  Mdigit: 1350,
  Ldigit: 1600,
}
