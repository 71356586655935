export const readRepositoriesCount = () => ({
  type: 'READ_REPOSITORIES_COUNT_REQUEST',
})

export const readRepositoriesCountSuccess = (payload, idKey) => ({
  idKey,
  payload,
  type: 'READ_REPOSITORIES_COUNT_SUCCESS',
})

export const readRepositories = onCompletion => ({
  onCompletion,
  type: 'READ_REPOSITORIES_REQUEST',
})

export const readRepositoriesSuccess = payload => ({
  payload,
  type: 'READ_REPOSITORIES_SUCCESS',
})

export const scanRepository = repositoryId => ({
  loadingId: repositoryId,
  payload: { repositoryId },
  type: 'SCAN_REPOSITORY_REQUEST',
})

export const scanRepositorySuccess = (repositoryId, result) => ({
  loadingId: repositoryId,
  payload: { repositoryId, result },
  type: 'SCAN_REPOSITORY_SUCCESS',
})

export const scanAllRepositories = repoType => ({
  repoType,
  type: 'SCAN_ALL_REPOSITORIES_REQUEST',
})

export const scanAllRepositoriesSuccess = payload => ({
  payload,
  type: 'SCAN_ALL_REPOSITORIES_SUCCESS',
})

export const readScanResultsRequest = repositoryId => ({
  loadingId: repositoryId,
  payload: { repositoryId },
  type: 'READ_SCAN_RESULTS_REQUEST',
})

export const readScanResultsSuccess = (repositoryId, result) => ({
  loadingId: repositoryId,
  payload: { repositoryId, result },
  type: 'READ_SCAN_RESULTS_SUCCESS',
})

export const readScanResultsFailure = (repositoryId, reason) => ({
  loadingId: repositoryId,
  reason,
  type: 'READ_SCAN_RESULTS_FAILURE',
})

export const readAllScanResultsRequest = repoType => ({
  repoType,
  type: 'READ_ALL_SCAN_RESULTS_REQUEST',
})

export const readAllScanResultsSuccess = payload => ({
  payload,
  type: 'READ_ALL_SCAN_RESULT_SUCCESS',
})

export const readWebhooksSuccess = payload => ({
  payload,
  type: 'READ_WEBHOOKS_SUCCESS',
})

export const readWebhooksFailure = (repositoryId, statusCode) => ({
  loadingId: repositoryId,
  repositoryId,
  statusCode,
  type: 'READ_WEBHOOKS_FAILURE',
})
