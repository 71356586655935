import { connect } from 'react-redux'
import withSizes from 'react-sizes'

import { setUserView } from '../../state/actions/views'
import {
  deleteEmailRequest,
  createEmailRequest,
} from '../../state/actions/emails'
import { readUser, updateUser } from '../../state/actions/user'
import { breakpoint } from '../../theme'
import {
  currentViewSelector,
  viewModeSelector,
  isCurrentViewOwnerSelector,
} from '../../state/selectors/views'
import {
  deleteSquadRequest,
  removeMemberRequest,
  inviteMemberRequest,
  deleteInvitationRequest,
  leaveSquadRequest,
  changeMemberStatusRequest,
  transferOwnershipRequest,
} from '../../state/actions/squads'
import { deletedEmailLoadingSelector } from '../../state/selectors/loading'

import Settings from './Settings.component'

const mapStateToProps = state => ({
  currentView: currentViewSelector(state),
  deleteLoading: deletedEmailLoadingSelector(state),
  isOwner: isCurrentViewOwnerSelector(state),
  isUserMode: viewModeSelector(state) === 'user',
  user: state.user,
})

const mapDispatchToProps = {
  confirmMember: (squadId, memberId) =>
    changeMemberStatusRequest(squadId, memberId, 'member'),
  createEmail: createEmailRequest,
  deleteEmail: deleteEmailRequest,
  deleteInvitation: deleteInvitationRequest,
  deleteSquad: deleteSquadRequest,
  inviteMember: inviteMemberRequest,
  leaveSquad: leaveSquadRequest,
  readUser,
  removeMember: removeMemberRequest,
  setUserView,
  transferOwnership: transferOwnershipRequest,
  updateUser,
}

const mapSizesToProps = ({ width }) => ({
  showIllustration: width > breakpoint.Ldigit,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(Settings))
