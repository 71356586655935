import React, { useEffect } from 'react'
import 'styled-components/macro'
import { Loader } from '@gitguardian/gg-front'

import { ENABLE_SQUADS } from '../../utils/config'
/*
  AuthenticationBouncer
  loads the user data if signed in otherwise redirects to /signin
  The redirection occurs in the saga
*/

const AuthenticationBouncer = ({
  user,
  children,
  readUser,
  squads,
  readSquads,
}) => {
  useEffect(() => {
    if (!user) {
      readUser()
    }
  }, [readUser, user])
  useEffect(() => {
    if (!squads && ENABLE_SQUADS) {
      readSquads()
    }
  }, [readSquads, squads])

  if (!user || (!squads && ENABLE_SQUADS)) {
    return (
      <div
        css="
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <Loader />
      </div>
    )
  }
  return <>{children}</>
}

export default AuthenticationBouncer
