const apiUrl = process.env.REACT_APP_API_URL

/*
  get, post and put helpers
  to communicate with the API
*/

const xmlHttpRequest = (method, urlPart, body) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()

    request.addEventListener('load', () => {
      const { status, response } = request

      let json

      try {
        json = JSON.parse(response)
      } catch (error) {
        // nothing
      }

      if (!status.toString().startsWith('20')) {
        return reject({ data: json, statusCode: status })
      }

      resolve(json || response)
    })

    request.addEventListener('error', () => {
      const { response, status } = request
      let json
      try {
        json = response.json()
      } catch (error) {
        // nothing
      }
      return reject({ data: json, statusCode: status })
    })

    request.withCredentials = true

    request.open(method, apiUrl + urlPart)

    if (body) {
      request.setRequestHeader('Content-Type', 'application/json')
      request.send(JSON.stringify(body))
    } else {
      request.send()
    }
  })
}

export const get = urlPart => xmlHttpRequest('GET', urlPart)

export const post = (urlPart, body) => xmlHttpRequest('POST', urlPart, body)

export const put = (urlPart, body) => xmlHttpRequest('PUT', urlPart, body)

export const _delete = (urlPart, body) =>
  xmlHttpRequest('DELETE', urlPart, body)

export const patch = (urlPart, body) => xmlHttpRequest('PATCH', urlPart, body)
