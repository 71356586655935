import { connect } from 'react-redux'
import { newNotification } from '@gitguardian/gg-front'

import { readLeaks, updateLeakStatusRequest } from '../../state/actions/leaks'
import { readEvents } from '../../state/actions/events'
import {
  readCommitsBins,
  readAnalysedCommitsCount,
} from '../../state/actions/commits'
import { readRepositoriesCount } from '../../state/actions/repositories'
import {
  currentStatsSelector,
  createdAtSelector,
} from '../../state/selectors/stats'
import {
  currentViewIdSelector,
  viewModeSelector,
  currentStatusSelector,
} from '../../state/selectors/views'

import Dashboard from './Dashboard.component'

const mapStateToProps = state => ({
  createdAt: createdAtSelector(state),
  currentViewId: currentViewIdSelector(state),
  events: state.events,
  isPendingMember: currentStatusSelector(state) === 'pending',
  leaks: state.leaks,
  stats: currentStatsSelector(state),
  viewMode: viewModeSelector(state),
})

const mapDispatchToProps = {
  newNotification,
  readAnalysedCommitsCount,
  readCommitsBins,
  readEvents,
  readLeaks,
  readRepositoriesCount,
  updateLeakStatus: updateLeakStatusRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
