import styled from 'styled-components/macro'
import { ReactComponent as BaseCheckmark } from '../../assets/checkmark.svg'
import { ReactComponent as BaseCrossmark } from '../../assets/crossmark.svg'

export const Info = styled.div`
  margin-left: 10px;
  position: relative;
  display: flex;
`

export const Checkmark = styled(BaseCheckmark)`
  margin-right: 10px;
  height: 16px;
  width: 16px;
  fill: ${({ theme }) => theme.palette.green};
`

export const Crossmark = styled(BaseCrossmark)`
  margin-right: 10px;
  height: 16px;
  width: 16px;
  fill: ${({ theme }) => theme.palette.red};
`
