import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import 'styled-components/macro'

import {
  Button,
  Title,
  Text,
  Loader,
  Center,
  FlatterCard as Card,
} from '@gitguardian/gg-front'
import 'redux-saga/effects'

import ErrorBoundary from '../../components/ErrorBoundary'
import useFilter from '../../hooks/useFilter'

import Search from '../../components/Search'
import { Wrapper } from '../../components/PageUtils'
import RepositoriesTable from '../../components/RepositoriesTable'
import LoaderWithText from '../../components/Loader'
import { SearchBarWrapper } from './OwnedRepositories.style'

const accessorByName = {
  search: ['name'],
  select: 'type',
  type: 'name',
}

const OwnedRepositories = ({
  isOnboarding,
  readAllScanResults,
  readRepositories,
  repositories,
  scanAllRepositories,
  scanStatuses,
  viewMode,
}) => {
  const [workingRepositories, setOptions] = useFilter(
    repositories.owned,
    accessorByName
  )
  const [loadingWebhooks, setLoadingWebhooks] = useState(true)
  const [disableButton, setDisableButton] = useState(true)

  const checkWebhooks =
    repositories.owned.length === 0 ||
    repositories.owned.some(({ webhook_state }) => webhook_state === 'pending')

  useEffect(() => {
    if (!repositories.fetched) {
      readRepositories()
    }
  }, [readRepositories, repositories])

  useEffect(() => {
    if (repositories.fetched) {
      readAllScanResults('all')
    }
  }, [readAllScanResults, repositories.fetched])

  useEffect(() => {
    if (isOnboarding) {
      setLoadingWebhooks(checkWebhooks)
    }
  }, [checkWebhooks, isOnboarding, setLoadingWebhooks])

  useEffect(() => {
    if (
      repositories.fetched &&
      scanStatuses.fetched &&
      repositories.owned.some(
        ({ id }) => !Object.keys(scanStatuses).includes(id.toString())
      )
    ) {
      setDisableButton(false)
    }
  }, [repositories, scanStatuses])

  const SearchBar = (
    <SearchBarWrapper as={Card}>
      <Search setOptions={setOptions} />
      <Button
        disabled={disableButton}
        onClick={() => {
          scanAllRepositories('owned')
          setDisableButton(true)
        }}
      >
        Scan all
      </Button>
    </SearchBarWrapper>
  )

  return viewMode === 'squad' ? (
    <Redirect to="/" />
  ) : (
    <Wrapper>
      <Title>Owned repositories</Title>

      {isOnboarding && (
        <>
          <Text>
            We are going to retrieve all your public repositories to activate
            the real time protection
          </Text>
          <Text as="div" css="grid-column: 1 / -1;">
            <LoaderWithText
              loading={!repositories.fetched}
              text="Retrieving repositories"
            />
            {repositories.fetched && (
              <LoaderWithText
                loading={loadingWebhooks}
                text="Activating Real-time protection"
              />
            )}
          </Text>
        </>
      )}

      {repositories.fetched ? (
        <>
          <Text css="grid-column: 1 / -1;">
            Scan your public repositories to make sure they are clean!
          </Text>
          {SearchBar}
          <ErrorBoundary>
            <RepositoriesTable
              isOwner={true}
              repos={workingRepositories}
              scannable={() => !isOnboarding}
            />
          </ErrorBoundary>
        </>
      ) : (
        !isOnboarding && (
          <Center>
            <Loader />
          </Center>
        )
      )}
    </Wrapper>
  )
}

Button.defaultProps = {
  bgColor: 'lightPrimary',
  hoverBgColor: 'white',
  hoverTextColor: 'lightPrimary',
  textColor: 'white',
}

export default OwnedRepositories
