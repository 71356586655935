import styled from 'styled-components/macro'
import { Text, TitledCard as RawTitledCard } from '@gitguardian/gg-front'
import { Field as BaseField, ErrorMessage as BaseErrorMessage } from 'formik'

export const Field = styled(BaseField)`
  padding: 10px 20px;
  max-width: 450px;
  max-height: 50px;

  overflow-x: auto;
  color: ${({ theme }) => theme.palette.darkText};
  ${({ theme }) => theme.typography.text};
  border: 2px solid ${({ theme }) => theme.palette.border};
  border-radius: 6px;

  ::placeholder {
    opacity: 0.5;
  }
`

export const TitledCard = styled(RawTitledCard)`
  & > ${Text} {
    margin-bottom: 30px;
  }
`

export const ErrorMessage = styled(BaseErrorMessage)`
  grid-column: 2 / span 3;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.red};
`
