import styled, { css, keyframes } from 'styled-components/macro'

import { FlatFigures as BaseFlatFigures } from '@gitguardian/gg-front'

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Refresh = styled.button`
  & > i {
    margin-left: 10px;
  }

  ${({ animate }) =>
    animate &&
    css`
      & > i {
        animation: ${spin} 2s linear 0s 1;
      }
    `}
`

export const FlatFigures = styled(BaseFlatFigures)`
  > div:not(:empty) {
    /* change flex-basis of the figures  */
    flex-basis: 160px;

    @media (max-width: ${({ theme }) => theme.breakpoint.M}) {
      flex-basis: auto;
    }
  }
`
