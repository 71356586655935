import { call, takeEvery } from 'redux-saga/effects'

import enhanceSagaWithOnCompletion from '../../lib/enhanceSagaWithOnCompletion'
import {
  readCommitsBinsSuccess,
  readAnalysedCommitsCountSuccess,
} from '../actions/commits'
import { readRepositoriesCountSuccess } from '../actions/repositories'

import { callViewEndpoint } from './utils'

const urlPrefix = '/stats'

const callViewEndpointAndGenerateAction = (endpoint, actionGenerator) =>
  call(callViewEndpoint, urlPrefix, endpoint, actionGenerator)

function* readCommitsBins() {
  yield callViewEndpointAndGenerateAction(
    '/commits_over_time',
    readCommitsBinsSuccess
  )
}

function* readRepositoriesCount() {
  yield callViewEndpointAndGenerateAction(
    '/repositories_count',
    readRepositoriesCountSuccess
  )
}

function* readAnalysedCommitsCount() {
  yield callViewEndpointAndGenerateAction(
    '/analysed_commits_count',
    readAnalysedCommitsCountSuccess
  )
}

function* statsSaga() {
  yield takeEvery(
    'READ_COMMITS_BINS',
    enhanceSagaWithOnCompletion(readCommitsBins)
  )
  yield takeEvery('READ_REPOSITORIES_COUNT_REQUEST', readRepositoriesCount)
  yield takeEvery('READ_ANALYSED_COMMITS_COUNT', readAnalysedCommitsCount)
}

export default statsSaga
