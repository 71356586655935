import { all, put, call, delay, select, takeEvery } from 'redux-saga/effects'

import enhanceSagaWithOnCompletion from '../../lib/enhanceSagaWithOnCompletion'
import { get, post } from '../../lib/xmlHttpRequest'
import {
  readRepositoriesSuccess,
  scanRepositorySuccess,
  readScanResultsSuccess,
  readScanResultsFailure,
  readWebhooksSuccess,
  readWebhooksFailure,
  readScanResultsRequest,
  readAllScanResultsSuccess,
  scanAllRepositoriesSuccess,
} from '../actions/repositories'
import { repoIdsSelector, ownedRepoSelector } from '../selectors/repositories'

const urlPrefix = '/repositories'

function* readRepositories() {
  try {
    const response = yield call(get, urlPrefix)

    yield put(readRepositoriesSuccess(response))
    if (
      response.owned.some(({ webhook_state }) => webhook_state === 'pending')
    ) {
      yield call(readAllWebhooks)
    }
  } catch (error) {
    // Todo set notifications
  }
}

function* scanRepository(action) {
  const { repositoryId } = action.payload
  try {
    const response = yield call(get, `${urlPrefix}/${repositoryId}/scan`)
    yield put(scanRepositorySuccess(repositoryId, response))
    if (response.status_code === 'running') {
      yield put(readScanResultsRequest(repositoryId))
    }
  } catch (error) {
    // Todo set notifications
  }
}

function* scanAllRepositories(action) {
  try {
    const repositoryIds = yield select(repoIdsSelector, action.repoType)
    const response = yield call(post, `${urlPrefix}/scan`, {
      ids: repositoryIds,
    })
    yield put(scanAllRepositoriesSuccess(response))
    if (response.some(({ status_code }) => status_code === 'running')) {
      yield call(readAllScanResults, { repoType: action.repoType })
    }
  } catch (error) {
    // Todo set notifications
  }
}

function* readScanResults(action) {
  const { repositoryId } = action.payload
  try {
    const response = yield call(pollScanResults, repositoryId)
    yield put(readScanResultsSuccess(repositoryId, response))
  } catch (error) {
    yield put(readScanResultsFailure(repositoryId, error.reason))
  }
}

/* This saga fetches the scan result from the backend
and retries if the scan has the status 'running'. If the scan is still running after
MAX_TRIES tries, it throws a error with statusCode 408 timeout
 */
function* pollScanResults(repositoryId) {
  const DELAY = 1000 // 1 second
  const MAX_TRIES = 1000
  for (let i = 0; i < MAX_TRIES; i++) {
    const response = yield call(
      get,
      `${urlPrefix}/${repositoryId}/scan-results`
    )
    if (response.status_code === 'running') {
      yield delay(DELAY)
    } else {
      return response
    }
  }
  throw new Error({ message: 'scan timed out', reason: 'timeout' })
}

function* readAllScanResults(action) {
  const DELAY = 1000 // 1 second
  const MAX_TRIES = 300 // 5 minutes
  let repositoryIds = yield select(repoIdsSelector, action.repoType)
  try {
    for (let i = 0; i < MAX_TRIES; i++) {
      const response = yield call(post, `${urlPrefix}/scan-results`, {
        ids: repositoryIds,
      })
      yield put(readAllScanResultsSuccess(response))

      // We keep only the ids for scans that are still running, to avoid
      // retrieving the others
      repositoryIds = response
        .filter(({ status_code }) => status_code === 'running')
        .map(({ repository_id }) => repository_id)
      if (repositoryIds.length === 0) {
        // if not scan is still running, we stop polling results
        break
      }
      // otherwise we wait before retrying
      yield delay(DELAY)
    }
    yield all(
      repositoryIds.map(id => put(readScanResultsFailure(id, 'timeout')))
    )
  } catch (error) {
    yield all(
      repositoryIds.map(id => put(readScanResultsFailure(id, error.reason)))
    )
  }
}

function* readAllWebhooks() {
  const DELAY = 2000 // 1 second
  const MAX_TRIES = 10
  const ownedRepos = yield select(ownedRepoSelector)
  let repositoryIds = ownedRepos.map(({ id }) => id)
  try {
    for (let i = 0; i < MAX_TRIES; i++) {
      const response = yield call(post, `${urlPrefix}/hooks`, {
        ids: repositoryIds,
      })
      yield put(readWebhooksSuccess(response))

      // We keep only the ids for scans that are still running, to avoid
      // retrieving the others
      repositoryIds = ownedRepos
        .filter(({ id, archived }) => !archived && !response[id])
        .map(({ id }) => id)
      if (repositoryIds.length === 0) {
        // if not webhooks is still pending, we stop polling results
        break
      }
      // otherwise we wait before retrying
      yield delay(DELAY)
    }
    yield all(repositoryIds.map(id => put(readWebhooksFailure(id, 'timeout'))))
  } catch (error) {
    yield all(
      repositoryIds.map(id => put(readWebhooksFailure(id, error.reason)))
    )
  }
}

function* repositoriesSaga() {
  yield takeEvery(
    'READ_REPOSITORIES_REQUEST',
    enhanceSagaWithOnCompletion(readRepositories)
  )
  yield takeEvery('SCAN_ALL_REPOSITORIES_REQUEST', scanAllRepositories)
  yield takeEvery('SCAN_REPOSITORY_REQUEST', scanRepository)
  yield takeEvery('READ_SCAN_RESULTS_REQUEST', readScanResults)
  yield takeEvery('READ_ALL_SCAN_RESULTS_REQUEST', readAllScanResults)
}

export default repositoriesSaga
