import React, { useReducer } from 'react'
import 'styled-components/macro'
import { parseISO, format } from 'date-fns'

import {
  TruncatedText,
  ReactTable,
  TruncatedCell,
  Arrow,
  GHStyledLink as StyledLink,
} from '@gitguardian/gg-front'

import NoData from '../../components/NoData'
import SecretIcon from '../../assets/valid_file.svg'
import { dateTimeFormat } from '../../utils/time'
import { Leak } from '../../components/LeakList'

const initialState = {}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'TOGGLE': {
      const { id } = payload
      return { ...state, [id]: !state[id] }
    }
    case 'RESET':
      return initialState
    default:
      throw new Error(`Type ${type} unknown`)
  }
}

const rank = {
  assigned: 2,
  resolved: 1,
  triggered: 3,
}
const rankStatus = status => (rank[status] ? rank[status] : 0)

const sortStatus = (a, b) => (rankStatus(a) < rankStatus(b) ? 1 : -1)

const SecretTable = ({ secrets, updateLeakStatus }) => {
  const [expanded, dispatch] = useReducer(reducer, initialState)

  const secretColumns = [
    {
      Cell: ({ value }) => (
        <TruncatedText>{format(parseISO(value), dateTimeFormat)}</TruncatedText>
      ),
      Header: 'Date',
      accessor: 'commit.committed_at',
      minWidth: 200,
    },
    {
      Cell: ({ value, original }) => (
        <TruncatedText>
          <StyledLink
            href={`https://github.com/${original.user.login}/${value}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {original.user.login}/{value}
          </StyledLink>
        </TruncatedText>
      ),
      Header: 'Source',
      accessor: 'repository.name',
      minWidth: 250,
    },
    {
      Cell: ({ value }) => (
        <StyledLink
          href={`https://github.com/${value}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </StyledLink>
      ),
      Header: 'User',
      accessor: 'user.login',
      minWidth: 150,
    },
    {
      Cell: TruncatedCell,
      Header: 'Type',
      accessor: 'secrets[0].company_name',
      minWidth: 150,
    },
    {
      Cell: ({ value }) => (
        <TruncatedText
          css={`
            text-transform: capitalize;
          `}
        >
          {value}
        </TruncatedText>
      ),
      Header: 'Status',
      accessor: 'status',
      minWidth: 150,
      sortMethod: sortStatus,
    },

    {
      Expander: ({ isExpanded, viewIndex }) => {
        return (
          <div
            onClick={() =>
              dispatch({
                payload: {
                  id: viewIndex,
                },
                type: 'TOGGLE',
              })
            }
          >
            <Arrow direction={isExpanded ? 'up' : 'down'} size="24" />
          </div>
        )
      },
      Header: 'Action',
      expander: true,
      width: 90,
    },
  ]
  return (
    <>
      {secrets.length ? (
        <ReactTable
          collapseOnDataChange={false}
          columns={secretColumns}
          data={secrets}
          expanded={expanded}
          noDataText="No incidents"
          onExpandedChange={null}
          onPageChange={() => dispatch({ type: 'RESET' })}
          SubComponent={({ original }) => (
            <Leak {...original} updateLeakStatus={updateLeakStatus} />
          )}
        />
      ) : (
        <NoData
          caption="No secrets"
          description="No leak events were detected. Well Done!"
          Image={SecretIcon}
        />
      )}
    </>
  )
}

export default SecretTable
