export const readUser = () => ({
  type: 'READ_USER',
})

export const readUserSuccess = payload => ({
  payload,
  type: 'READ_USER_SUCCESS',
})

export const signOut = () => ({
  type: 'SIGN_OUT',
})

export const resetUser = () => ({
  type: 'RESET_USER',
})

export const updateUser = payload => ({
  payload,
  type: 'UPDATE_USER',
})

export const updateUserOptimistic = payload => ({
  payload,
  type: 'UPDATE_USER_OPTIMISTIC',
})

export const updateUserSuccess = payload => ({
  payload,
  type: 'UPDATE_USER_SUCCESS',
})

export const onboardingNextStep = (nextonboardingStepIndex, nextUrl) => ({
  payload: {
    nextUrl,
    nextonboardingStepIndex,
  },
  type: 'ONBOARDING_NEXT_STEP',
})
