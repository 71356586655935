import React, { memo } from 'react'
import { format, parseISO } from 'date-fns'

import {
  TruncatedText,
  GHStyledLink as StyledLink,
  Spinner,
  Table,
  TruncatedCell,
} from '@gitguardian/gg-front'

import { dateTimeFormat } from '../../utils/time'

const EmailsTable = ({ deleteLoading, onDelete, emails, view, canDelete }) => {
  const columns = [
    { Cell: TruncatedCell, Header: 'Email', accessor: 'email', minWidth: 200 },
    {
      Cell: ({ value }) => (
        <TruncatedText>{format(parseISO(value), dateTimeFormat)}</TruncatedText>
      ),
      Header: view.mode === 'user' ? 'Added on' : 'Expires on',
      accessor: view.mode === 'user' ? 'gg_created_at' : 'expires',
      defaultSortDesc: true,
    },
    {
      Cell: ({ original }) =>
        canDelete && (
          <TruncatedText>
            <StyledLink color="red" onClick={() => onDelete(original.id)}>
              delete
              {deleteLoading && deleteLoading[original.id] === 'REQUEST' && (
                <Spinner small />
              )}
            </StyledLink>
          </TruncatedText>
        ),
      Header: 'Action',
    },
  ]
  return (
    <Table columns={columns} data={emails} minRows={3} noDataText="No emails" />
  )
}
export default memo(EmailsTable)
