const loading = (state = {}, action) => {
  const { type, loadingId } = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) {
    return state
  }

  // type = "READ_SQUAD_SUCCESS" => matches = [ "READ_SQUAD_SUCCESS", "READ_SQUAD", "SUCCESS" ]
  const [, requestName, requestState] = matches
  if (loadingId === undefined) {
    return {
      ...state,
      [requestName]: requestState,
    }
  }
  return {
    ...state,
    [`${requestName}_BY_ID`]: {
      ...state[`${requestName}_BY_ID`],
      [loadingId]: requestState,
    },
  }
}

export default loading
