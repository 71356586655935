import React from 'react'
import { Redirect } from 'react-router-dom'
import 'styled-components/macro'
import {
  Spinner,
  Center,
  Text,
  Title,
  FlatterCard as Card,
} from '@gitguardian/gg-front'

import MembersTable from '../../components/MembersTable'
import { Wrapper } from '../../components/PageUtils'

const SquadMembers = ({
  isOwner,
  currentView: squad,
  deleteSquad,
  removeMember,
  leaveSquad,
  currentUserId,
  confirmMember,
}) => {
  return squad.mode === 'user' ? (
    <Redirect to="/" />
  ) : (
    <Wrapper>
      <Title>Squad Members</Title>

      <Text>
        These are all of the people in your squad.
        {isOwner &&
          squad.members &&
          squad.members.length < squad.max_size &&
          ' You can add new members on the settings page.'}
      </Text>
      <Card css="grid-column: 1 / -1">
        {!squad.members && (
          <Center>
            <Spinner />
          </Center>
        )}
        <MembersTable
          confirmMember={confirmMember}
          currentUserId={currentUserId}
          deleteSquad={deleteSquad}
          isOwner={isOwner}
          leaveSquad={leaveSquad}
          members={squad.members}
          removeMember={removeMember}
          squadId={squad.id}
          viewMode={'squad'}
        />
      </Card>
    </Wrapper>
  )
}

export default SquadMembers
