const getWebhookState = (repo, payload) => {
  if (payload[repo.id]) {
    return 'set'
  } else if (repo.archived) {
    return 'archived'
  }
  return 'pending'
}

const repositories = (
  state = { fetched: false, not_owned: [], owned: [] },
  action
) => {
  switch (action.type) {
    case 'READ_REPOSITORIES_SUCCESS':
      return { ...action.payload, fetched: true }
    case 'READ_WEBHOOKS_SUCCESS':
      return {
        ...state,
        owned: state.owned.map(repo =>
          Object.keys(action.payload).includes(repo.id.toString())
            ? { ...repo, webhook_state: getWebhookState(repo, action.payload) }
            : repo
        ),
      }

    default:
      return state
  }
}

export default repositories
