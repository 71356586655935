import React from 'react'
import 'styled-components/macro'

import { Y, Text } from '@gitguardian/gg-front'

const NoData = ({ Image, caption, description }) => (
  <Y
    css={`
      margin: -15px auto 0;
      max-width: 220px;
      text-align: center;
    `}
  >
    <img alt="event" height="89px" src={Image} width="70px" />
    <Text color="secondaryText" typo="caption">
      {caption}
    </Text>
    <Text>{description}</Text>
  </Y>
)

export default NoData
