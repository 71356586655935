import styled, { css } from 'styled-components/macro'
import { Button, Center } from '@gitguardian/gg-front'

export const NextButton = styled(Button)`
  ${({ theme }) => theme.mixin.pulse}
`

export const StepCircle = styled(Center)`
  background-color: white;
  width: 30px;
  min-width: 30px;
  height: 30px;

  border-radius: 50%;
  transition: all 0.2s linear;

  ${({ selected }) =>
    selected
      ? css`
          background-color: ${({ theme }) => theme.palette.darkPrimary};
          color: ${({ theme }) => theme.palette.lightPrimary};
        `
      : css`
          cursor: pointer;
          background-color: ${({ theme }) => theme.palette.lightPrimary};
          color: ${({ theme }) => theme.palette.primary};
        `}
`

export const StepName = styled.span`
  max-width: 150px;
  transition: color 0.2s linear;
  margin-left: 0.5rem;
  ${({ theme, selected }) =>
    selected
      ? `
          color: ${theme.palette.darkPrimary};
        `
      : `color: ${theme.palette.lightPrimary};`}

  @media (max-width: ${({ theme }) => theme.breakpoint.M}) {
    max-width: unset;
  }
`

export const Step = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  + :not(:first-child) {
    margin-top: 25px;
  }
`

export const XDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.border};
  height: 2px;
  width: 32px;
  margin: 8px;
`

export const YDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.border};
  height: 16px;
  width: 2px;
  margin-left: 15px;
`

export const Skip = styled.div`
  margin: 20px 0 50px;

  color: ${({ theme }) => theme.palette.secondary};
  ${({ theme }) => theme.typography.text};
  cursor: pointer;
`

export const WrapperSmallScreen = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0px;
  left: 0px;
  z-index: 500;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;

  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.white};
  border-top: 1px solid ${({ theme }) => theme.palette.border};

  > :not(:first-child) {
    margin-top: 20px;
  }
`
