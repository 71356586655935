import React, { useState } from 'react'
import { parseISO, format } from 'date-fns'

import ElapsedTimeCounter from '../ElapsedTimeCounter'
import { Wrapper, Lead } from './ScanResult.style'
import { CommitLeak } from '../leaks/Leak.component'
import { longDayFormat } from '../../utils/time'

import { pluralize } from '@gitguardian/gg-front'

const getApiDuration = scanStatus =>
  scanStatus
    ? Math.ceil(
        (new Date(scanStatus.ended_at).getTime() -
          new Date(scanStatus.started_at).getTime()) /
          1000
      )
    : null

const ScanResult = ({ scanStatus }) => {
  const [duration, setDuration] = useState(getApiDuration(scanStatus))

  if (!scanStatus) {
    return null
  }

  if (scanStatus.status_code === 'running') {
    return (
      <Wrapper type="info">
        Scanning...{' '}
        <ElapsedTimeCounter
          setDuration={setDuration}
          since={parseISO(scanStatus.requested_at)}
        />
      </Wrapper>
    )
  }

  if (scanStatus.status_code === 'skipped') {
    return (
      <Wrapper type="info">
        This repository is a fork, therefore it cannot be scanned. Only the
        owner of the original repository can scan it.
      </Wrapper>
    )
  }

  if (scanStatus.status_code === 'timeout') {
    return (
      <Wrapper type="info">
        This repository is currently too big for our scanner, we will let you
        know when that changes.
      </Wrapper>
    )
  }

  if (scanStatus.status_code === 'error') {
    return (
      <Wrapper type="info">
        We encountered an error while processing your repo. Please try again
        later
      </Wrapper>
    )
  }

  const scanDate = format(parseISO(scanStatus.requested_at), longDayFormat)

  if (scanStatus.status_code === 'leaks') {
    return (
      <Wrapper type="danger">
        <div>
          {scanStatus.requested_at && (
            <p>This repository was scanned on {scanDate}</p>
          )}
          <Lead>
            {scanStatus.leak_count} leak
            {scanStatus.leak_count > 1 ? 's were' : ' was'} found in the
            repository.
          </Lead>

          <ol>
            {scanStatus.commit_leaks.map((commit_leak, i) => (
              <CommitLeak
                commitSha={commit_leak.commit_sha}
                commitUrl={commit_leak.html_url}
                fileLeaks={commit_leak.file_leaks}
                key={i}
              />
            ))}
          </ol>
        </div>
        <div>
          <p>Commits scanned: {scanStatus.commit_count}</p>
          <p>
            Duration: {duration} {pluralize('second', duration)}
          </p>
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div>
        {scanStatus.requested_at && (
          <p>This repository was scanned on {scanDate}</p>
        )}
        <Lead type="success">No credentials were found in the repository.</Lead>
      </div>
      <div>
        <p>Commits scanned: {scanStatus.commit_count}</p>
        <p>
          Duration: {duration} {pluralize('second', duration)}
        </p>
      </div>
    </Wrapper>
  )
}

export default ScanResult
