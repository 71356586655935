import styled, { keyframes } from 'styled-components/macro'

import { FlatterCard } from '@gitguardian/gg-front'
import { ReactComponent as BaseBackground } from '../../assets/404/background.svg'
import { ReactComponent as BaseStars } from '../../assets/404/stars.svg'

const StarsAnim = keyframes`
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0.7;
  }
`

export const SVGWrapper = styled.div`
  position: relative;
  height: 100%;

  > div > svg {
    /* inline style */
    max-height: 500px !important;
    width: auto !important;
    max-width: 100% !important;
  }
`

export const Background = styled(BaseBackground)`
  position: absolute;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 50px 0 0;
`

export const Stars = styled(BaseStars)`
  position: absolute;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  padding: 50px 0 0;
  .stars2,
  .stars3 {
    animation: ${StarsAnim} 1.5s ease-in-out infinite alternate;
  }
`

export const Card = styled(FlatterCard)`
  padding: 70px 30px;
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.M}) {
    flex-direction: column;
    > div {
      margin-bottom: 30px;
    }
  }
`
