import React, { useState } from 'react'

import { useInterval } from '@gitguardian/gg-front'

const ElapsedTimeCounter = ({ since, setDuration }) => {
  const [timer, setTimer] = useState(new Date() - since)

  useInterval(() => setTimer(new Date() - since), 1000)

  const seconds = Math.floor(timer / 1000)

  setDuration(seconds)

  return <span>{seconds}s</span>
}

export default ElapsedTimeCounter
