import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import {
  theme as ggTheme,
  newTypography,
  Button,
  GlobalStyle,
} from '@gitguardian/gg-front'

import { mixin, breakpoint } from '../../theme'
import 'normalize.css'
import '@fortawesome/fontawesome-free/css/all.css'

const SwitchableTheme = ({ children, viewMode }) => {
  const theme = {
    ...ggTheme,
    breakpoint,
    mixin: { ...ggTheme.mixin, ...mixin },
    palette: ggTheme.palette,
    typography: { ...newTypography },
  }
  if (viewMode === 'squad') {
    theme.palette = {
      ...theme.palette,
      lightPrimary: '#ffffff',
      primary: '#30353a',
      secondary: theme.palette.squadSecondary,
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}

Button.defaultProps = {
  bgColor: 'lightPrimary',
  hoverBgColor: 'white',
  hoverTextColor: 'lightPrimary',
  textColor: 'white',
  withBorder: true,
}

export default SwitchableTheme
