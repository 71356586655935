import { put, call, takeEvery } from 'redux-saga/effects'

import enhanceSagaWithOnCompletion from '../../lib/enhanceSagaWithOnCompletion'
import { get } from '../../lib/xmlHttpRequest'
import { readSquadSuccess, readSquadsSuccess } from '../actions/views'

function* readSquad(action) {
  try {
    const response = yield call(get, `/squads/${action.payload.id}`)

    yield put(readSquadSuccess(response))
  } catch (error) {
    // Todo set notifications
  }
}

function* readSquads() {
  try {
    const response = yield call(get, `/squads`)

    yield put(readSquadsSuccess(response))
  } catch (error) {
    // Todo set notifications
  }
}

function* viewsSaga() {
  yield takeEvery('SET_SQUAD_VIEW', enhanceSagaWithOnCompletion(readSquad))
  yield takeEvery(
    'READ_SQUADS_REQUEST',
    enhanceSagaWithOnCompletion(readSquads)
  )
}

export default viewsSaga
