import React from 'react'
import styled from 'styled-components/macro'
import {
  WelcomePage,
  StyledLink,
  Text,
  Subtitle,
  Y,
} from '@gitguardian/gg-front'

const Wrapper = styled(Y)`
  > :not(:first-child) {
    margin-top: 50px;
  }
`

const AccountDeleted = () => (
  <WelcomePage>
    <Wrapper>
      <Subtitle>Thank you for using GitGuardian</Subtitle>

      <Text>Your account has been deleted.</Text>
      <Text>
        Please allow a few minutes for the real time protection to wear off.
        <br />
        You can send us your remarks at&nbsp;
        <StyledLink
          href="mailto:support@gitguardian.com"
          rel="noreferrer noopener"
          target="_blank"
        >
          support@gitguardian.com
        </StyledLink>
        .
      </Text>
      <Text>Goodbye!</Text>
    </Wrapper>
  </WelcomePage>
)

export default AccountDeleted
