export const initialState = []

const leaks = (state = initialState, action) => {
  switch (action.type) {
    case 'READ_LEAKS_SUCCESS':
      return action.payload
    case 'UPDATE_LEAK_STATUS_SUCCESS': {
      const updatedLeak = action.payload
      return state.map(leak =>
        leak.id === updatedLeak.id ? updatedLeak : leak
      )
    }
    default:
      return state
  }
}

export default leaks
