import { ENABLE_SQUADS } from '../../utils/config'

import { userSelector } from './user'

export const squadsSelector = state => state.squads

export const currentViewIdSelector = state =>
  ENABLE_SQUADS ? state.views.currentViewId : 'user'

export const viewModeSelector = state =>
  ENABLE_SQUADS ? state.views.mode : 'user'

export const viewsSelector = state => ({
  user: userSelector(state),
  ...squadsSelector(state),
})
export const currentViewSelector = state => ({
  ...viewsSelector(state)[currentViewIdSelector(state)],
  mode: viewModeSelector(state),
})

export const currentStatusSelector = state => {
  if (viewModeSelector(state) === 'user') {
    return 'owner'
  }
  const { members } = currentViewSelector(state)
  if (members === undefined) {
    return 'unknown'
  }
  return members.find(m => m.id === userSelector(state).id).status
}

export const isCurrentViewOwnerSelector = state =>
  currentStatusSelector(state) === 'owner'
