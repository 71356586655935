import { connect } from 'react-redux'

import { setUserView, setSquadView } from '../../state/actions/views'
import {
  viewsSelector,
  currentViewSelector,
  viewModeSelector,
} from '../../state/selectors/views'
import { createSquadRequest } from '../../state/actions/squads'

import ViewSwitcher from './ViewSwitcher.component'

const mapStateToProps = state => ({
  currentView: currentViewSelector(state),
  user: state.user,
  viewMode: viewModeSelector(state),
  views: viewsSelector(state),
})

const mapDispatchToProps = {
  createSquad: createSquadRequest,
  setSquadView,
  setUserView,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSwitcher)
