import { useState, useMemo } from 'react'

import { filter } from '@gitguardian/gg-front'

const useFilter = (data, accessorByName) => {
  const [options, setOptions] = useState({})
  const filterOptions = useMemo(
    () =>
      Object.entries(options).map(([name, option]) => ({
        ...option,
        accessor: accessorByName[name],
      })),
    [accessorByName, options]
  )

  const filteredData = useMemo(
    () => (filterOptions.length ? filter(filterOptions, data) : data),
    [filterOptions, data]
  )
  return [filteredData, setOptions]
}

export default useFilter
