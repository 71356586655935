//@flow
export const deleteEmailRequest = (id: number): DeleteEmailRequest => ({
  id,
  loadingId: id,
  type: 'DELETE_EMAIL_REQUEST',
})

export const deleteEmailSuccess = (id: number): DeleteEmailSuccess => ({
  id,
  loadingId: id,
  type: 'DELETE_EMAIL_SUCCESS',
})

export const createEmailRequest = (
  userId: number,
  email: string,
  onCompletion: () => void
): CreateEmailRequest => ({
  onCompletion,
  payload: {
    added_by_user: true,
    email,
    primary: false,
    user_id: userId,
  },
  type: 'CREATE_EMAIL_REQUEST',
})

export const createEmailSuccess = (payload: UserEmail): CreateEmailSuccess => ({
  payload,
  type: 'CREATE_EMAIL_SUCCESS',
})
