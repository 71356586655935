import styled from 'styled-components/macro'
import { StyledLink } from '@gitguardian/gg-front'

const NOT_SELECTED_OPACITY = 0.3
const HOVER_OPACITY = 0.5

export const Nav = styled.div`
  margin-top: 60px;
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
`

Nav.Item = styled.div`
  width: 100%;
  padding-left: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ selected }) => !selected && NOT_SELECTED_OPACITY};
  transition: background-color 0.3s linear;

  :hover {
    opacity: ${HOVER_OPACITY};
  }
`

Nav.Block = styled(Nav.Item)`
  padding: 15px 30px;

  background-color: ${({ selected }) =>
    selected ? `rgba(0, 0, 0, 0.1)` : 'inherit'};
  ${({ theme }) => theme.typography.link} :hover {
    background-color: ${({ selected }) => !selected && `rgba(0, 0, 0, 0.25)`};
  }
`

Nav.Link = styled(StyledLink)`
  width: 100%;

  text-decoration: none !important;
  pointer-events: ${({ selected }) => selected && 'none'};
  color: ${({ theme }) => theme.palette.white};
`

Nav.Icon = styled.div`
  margin-right: 15px;
  & > svg {
    height: 25px;
    width: 25px;
    fill: currentColor;
  }
`

Nav.Title = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.typography.link}  
  color: ${({ theme }) => theme.palette.white};
`

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const BottomLinks = styled.div`
  width: 100%;
  margin-top: auto;
  padding-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  & > :not(:first-child) {
    margin-top: 30px;
  }
`
