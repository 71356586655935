import React, { useState, useMemo, memo } from 'react'
import { Redirect } from 'react-router-dom'
import 'styled-components/macro'

import {
  Title,
  Text,
  Button,
  GHStyledLink as StyledLink,
  TitledCard,
} from '@gitguardian/gg-front'

import CreateSquadModal from '../../components/CreateSquadModal'
import { DEFAULT_MAX_SQUAD_NUMBER, ENABLE_SQUADS } from '../../utils/config'
import { Wrapper } from '../../components/PageUtils'
import { ReactComponent as Quote } from '../../assets/quote.svg'
import SquadScreenshot from '../../assets/squad-screenshot.png'

import {
  CreateSquadWrapper,
  Mission,
  Divider,
  FourSentinels,
  OneSentinel,
  Screenshot,
} from './Squad.style'

const Squad = ({ createSquad, views, maxSquadNumber, viewMode }) => {
  const [modal, setModal] = useState(false)
  const max_squad_number = maxSquadNumber || DEFAULT_MAX_SQUAD_NUMBER

  const memoizedSquadNames = useMemo(
    () =>
      Object.values(views)
        .filter(view => view.mode === 'squad')
        .map(squad => squad.name),
    [views]
  )
  if (viewMode === 'squad' || !ENABLE_SQUADS) {
    return <Redirect to="/" />
  }
  return (
    <Wrapper>
      <Title>GG-Squad</Title>
      <Text css="grid-column: 1 / -1">
        Enable your team to securely push code on Github
      </Text>
      <TitledCard gridColumn="1 / -1" title="What is a GG-Squad?">
        <Text>
          GG-Squads allow you to team up with up to five developers to prevent
          your secrets from being stolen.
        </Text>
        <CreateSquadWrapper>
          <StyledLink
            href="https://www.gitguardian.com/gg-squad"
            rel="noopener noreferrer"
            target="_blank"
          >
            What is a squad and why should I create one?
          </StyledLink>
          {Object.keys(views).length < max_squad_number + 1 && (
            <Button onClick={() => setModal(!modal)}>Create a squad</Button>
          )}
        </CreateSquadWrapper>
      </TitledCard>
      <TitledCard
        gridColumn="1 / -1"
        title="One Dev Team, one Mission, one GG-Squad"
      >
        <Mission>
          <Mission.Row.Spaced>
            <Mission.Column>
              <Mission.Column.Part>
                <OneSentinel />
                <div>
                  <Text color="squadSecondary" typo="smallerSubtitle">
                    One Owner
                  </Text>
                  <Text color="squadSecondary" css="margin-bottom: 30px">
                    The first Sentinel
                  </Text>
                  <Text>Name your GG-Squad</Text>
                  <Text>Invite up to 4 other Sentinels</Text>
                </div>
              </Mission.Column.Part>
              <Divider />
              <Mission.Column.Part>
                <FourSentinels />
                <div>
                  <Text color="squadSecondary" typo="smallerSubtitle">
                    Five Sentinels
                  </Text>
                  <Text color="squadSecondary" css="margin-bottom: 30px">
                    Developers who care about security on GitHub
                  </Text>
                  <Text>Team up to defend your secrets</Text>
                  <Text>
                    Be alerted within 4 seconds after a Sentinel leaked a secret
                  </Text>
                  <Text>
                    Joining a Squad is the best way to show how much you care!
                  </Text>
                </div>
              </Mission.Column.Part>
            </Mission.Column>
            <Screenshot
              alt="screenshot of squad settings"
              src={SquadScreenshot}
            />
          </Mission.Row.Spaced>
          <Divider margin="90px 0" />
          <Mission.Row.Centered>
            <Quote height="30px" />
            <Text color="secondaryText" typo="caption">
              Friends don&#39;t let friends push code without GitGuardian
            </Text>
            <Quote css="transform: rotate(180deg);" height="30px" />
          </Mission.Row.Centered>
          <Mission.Row.Centered>
            {Object.keys(views).length < max_squad_number + 1 && (
              <Button onClick={() => setModal(!modal)}>Create a squad</Button>
            )}
          </Mission.Row.Centered>
        </Mission>
      </TitledCard>
      {modal && (
        <CreateSquadModal
          createSquad={createSquad}
          existingSquads={memoizedSquadNames}
          setModal={setModal}
        />
      )}
    </Wrapper>
  )
}

export default memo(Squad)
