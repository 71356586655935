export const scanStatusSelector = ({ scanStatuses }, id) => scanStatuses[id]
export const repoIdsSelector = ({ repositories }, repoType) => {
  switch (repoType) {
    case 'owned':
    case 'not_owned':
      return repositories[repoType].map(({ id }) => id)
    default:
      return repositories.owned
        .map(({ id }) => id)
        .concat(repositories.not_owned.map(({ id }) => id))
  }
}

export const ownedRepoSelector = ({ repositories }) => repositories.owned
