const initialState = {
  user: {
    analysedCommitsCount: null,
    commitsBins: null,
    repositoriesCount: null,
  },
}

const assignToKey = (state, statKey, idKey, payload) => ({
  ...state,
  [idKey]: { ...state[idKey], [statKey]: payload },
})

const stats = (state = initialState, action) => {
  if (action.type === 'READ_COMMITS_BINS_SUCCESS') {
    return assignToKey(state, 'commitsBins', action.idKey, action.payload)
  }

  if (action.type === 'READ_REPOSITORIES_COUNT_SUCCESS') {
    return assignToKey(state, 'repositoriesCount', action.idKey, action.payload)
  }

  if (action.type === 'READ_ANALYSED_COMMITS_COUNT_SUCCESS') {
    return assignToKey(
      state,
      'analysedCommitsCount',
      action.idKey,
      action.payload
    )
  }

  return state
}

export default stats
