import { connect } from 'react-redux'
import withSizes from 'react-sizes'

import { readUser } from '../../state/actions/user'
import { breakpoint } from '../../theme'
import {
  currentViewSelector,
  isCurrentViewOwnerSelector,
} from '../../state/selectors/views'
import {
  deleteSquadRequest,
  removeMemberRequest,
  leaveSquadRequest,
  changeMemberStatusRequest,
} from '../../state/actions/squads'
import { userSelector } from '../../state/selectors/user'

import SquadMembers from './SquadMembers.component'

const mapStateToProps = state => ({
  currentUserId: userSelector(state).id,
  currentView: currentViewSelector(state),
  isOwner: isCurrentViewOwnerSelector(state),
})

const mapDispatchToProps = {
  confirmMember: (squadId, memberId) =>
    changeMemberStatusRequest(squadId, memberId, 'member'),
  deleteSquad: deleteSquadRequest,
  leaveSquad: leaveSquadRequest,
  readUser,
  removeMember: removeMemberRequest,
}

const mapSizesToProps = ({ width }) => ({
  showIllustration: width > breakpoint.Ldigit,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(SquadMembers))
