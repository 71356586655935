import styled from 'styled-components/macro'

import { X, Subtitle, Button, SquadButton } from '@gitguardian/gg-front'

import RawTimes from '@gitguardian/ui/lib/components/Times'

export const Title = styled(X)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.primary};
  justify-content: space-between;

  > ${Subtitle} {
    ${({ theme }) => theme.mixin.truncateText};
  }
`

export const Footer = styled(X)`
  padding-top: 10px;
  margin-top: 20px;
  justify-content: flex-end;

  > ${SquadButton},${Button} {
    margin-left: 25px;
  }

`
export const Times = styled(RawTimes)`
  margin-left: 10px;
  flex-shrink: 0;
  cursor: pointer;
`
