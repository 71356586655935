import { connect } from 'react-redux'
import withSizes from 'react-sizes'

import {
  readRepositories,
  readAllScanResultsRequest,
} from '../../state/actions/repositories'
import { breakpoint } from '../../theme'
import { viewModeSelector } from '../../state/selectors/views'

import CollaboratingRepositories from './CollaboratingRepositories.component'

const mapStateToProps = state => ({
  repositories: state.repositories,
  scanStatuses: state.scanStatuses,
  viewMode: viewModeSelector(state),
})

const mapDispatchToProps = {
  readAllScanResults: readAllScanResultsRequest,
  readRepositories,
}

const mapSizesToProps = ({ width }) => ({
  showIllustration: width > breakpoint.Ldigit,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(CollaboratingRepositories))
