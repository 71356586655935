//@flow
import { put, call, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { newNotification } from '@gitguardian/gg-front'

import enhanceSagaWithOnCompletion from '../../lib/enhanceSagaWithOnCompletion'
import { post, _delete, patch } from '../../lib/xmlHttpRequest'
import {
  joinSquadSuccess,
  joinSquadFailure,
  createSquadSuccess,
  createSquadFailure,
  deleteSquadSuccess,
  deleteSquadFailure,
  inviteMemberSuccess,
  inviteMemberFailure,
  deleteInvitationSuccess,
  deleteInvitationFailure,
  leaveSquadSuccess,
  leaveSquadFailure,
  removeMemberSuccess,
  removeMemberFailure,
  changeMemberStatusSuccess,
  changeMemberStatusFailure,
  transferOwnershipSuccess,
  transferOwnershipFailure,
} from '../actions/squads'
import { setUserView, setSquadView } from '../actions/views'
import { errorMessages } from '../../utils/config'

const urlPrefix = '/squads'

function* joinSquad({ token }) {
  try {
    const response = yield call(post, `${urlPrefix}/join?squad_token=${token}`)
    yield put(joinSquadSuccess(response))
    yield put(setSquadView(response.id))
    yield put(
      newNotification({
        message: 'Squad joined successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(joinSquadFailure())
    yield put(
      newNotification({ message: errorMessages.invalid_token, type: 'error' })
    )
  }
}

function* createSquad({ name }) {
  try {
    const response: Squad = yield call(post, urlPrefix, { name })
    yield put(createSquadSuccess(response))
    yield put(push(`/`))
    yield put(setSquadView(response.id))
    yield put(
      newNotification({
        message: 'Squad created successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(createSquadFailure())
    yield put(
      newNotification({
        message: 'Failed to create squad',
        type: 'failure',
      })
    )
  }
}

function* deleteSquad({ squadId }) {
  try {
    yield call(_delete, `${urlPrefix}/${squadId}`)
    yield put(setUserView())
    yield put(deleteSquadSuccess(squadId))
    yield put(
      newNotification({
        message: 'Squad deleted successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(deleteSquadFailure())
    yield put(
      newNotification({ message: 'Failed to delete squad', type: 'error' })
    )
  }
}

function* inviteMember({ squadId, email }) {
  try {
    const response: SquadToken = yield call(
      post,
      `${urlPrefix}/${squadId}/tokens`,
      {
        email,
      }
    )
    yield put(inviteMemberSuccess(squadId, response))
    yield put(
      newNotification({
        message: 'Member invited successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(inviteMemberFailure())
    yield put(
      newNotification({ message: 'Failed to invite member', type: 'error' })
    )
  }
}
function* deleteInvitation({ squadId, tokenId }) {
  try {
    yield call(_delete, `${urlPrefix}/${squadId}/tokens/${tokenId}`)
    yield put(deleteInvitationSuccess(squadId, tokenId))
    yield put(
      newNotification({
        message: 'Invitation deleted successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(deleteInvitationFailure())
    yield put(
      newNotification({ message: 'Failed to delete invitation', type: 'error' })
    )
  }
}

function* leaveSquad({ squadId }) {
  try {
    yield call(_delete, `${urlPrefix}/${squadId}/members`)
    yield put(setUserView())
    yield put(leaveSquadSuccess(squadId))
    yield put(
      newNotification({
        message: 'Squad left successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(leaveSquadFailure())
    yield put(
      newNotification({ message: 'Failed to leave squad', type: 'error' })
    )
  }
}
function* removeMember({ squadId, memberId }) {
  try {
    yield call(_delete, `${urlPrefix}/${squadId}/members/${memberId}`)
    yield put(removeMemberSuccess(squadId, memberId))
    yield put(
      newNotification({
        message: 'Member removed successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(removeMemberFailure())
    yield put(
      newNotification({
        message: 'Failed to remove squad member',
        type: 'error',
      })
    )
  }
}
function* changeMemberStatus({ squadId, memberId, status }) {
  try {
    const response: SquadMember = yield call(
      patch,
      `${urlPrefix}/${squadId}/members/${memberId}`,
      { status }
    )
    yield put(changeMemberStatusSuccess(squadId, response))
    yield put(
      newNotification({
        message: 'Member status updated successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(changeMemberStatusFailure())
    yield put(
      newNotification({
        message: 'Failed to change member status',
        type: 'error',
      })
    )
  }
}
function* transferOwnership({ squadId, memberId }) {
  try {
    const response: Squad = yield call(patch, `${urlPrefix}/${squadId}`, {
      user_id: memberId,
    })
    yield put(transferOwnershipSuccess(response))
    yield put(
      newNotification({
        message: 'Ownership transfered successfully',
        type: 'success',
      })
    )
  } catch (error) {
    yield put(transferOwnershipFailure())
    yield put(
      newNotification({
        message: 'Failed to transfer ownership',
        type: 'error',
      })
    )
  }
}

function* squadsSaga(): any {
  yield takeEvery('JOIN_SQUAD_REQUEST', enhanceSagaWithOnCompletion(joinSquad))
  yield takeEvery(
    'CREATE_SQUAD_REQUEST',
    enhanceSagaWithOnCompletion(createSquad)
  )
  yield takeEvery(
    'DELETE_SQUAD_REQUEST',
    enhanceSagaWithOnCompletion(deleteSquad)
  )
  yield takeEvery(
    'INVITE_SQUAD_MEMBER_REQUEST',
    enhanceSagaWithOnCompletion(inviteMember)
  )
  yield takeEvery(
    'DELETE_INVITATION_REQUEST',
    enhanceSagaWithOnCompletion(deleteInvitation)
  )
  yield takeEvery(
    'LEAVE_SQUAD_REQUEST',
    enhanceSagaWithOnCompletion(leaveSquad)
  )
  yield takeEvery(
    'REMOVE_MEMBER_REQUEST',
    enhanceSagaWithOnCompletion(removeMember)
  )
  yield takeEvery(
    'CHANGE_MEMBER_STATUS_REQUEST',
    enhanceSagaWithOnCompletion(changeMemberStatus)
  )
  yield takeEvery(
    'TRANSFER_OWNERSHIP_REQUEST',
    enhanceSagaWithOnCompletion(transferOwnership)
  )
}

export default squadsSaga
