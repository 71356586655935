import React, { memo } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components/macro'

import { Spinner, X } from '@gitguardian/gg-front'

import { ReactComponent as BaseCheckmark } from '../assets/checkmark.svg'
import { ReactComponent as BaseCrossmark } from '../assets/crossmark.svg'

export const Checkmark = styled(BaseCheckmark)`
  height: 13px;
  width: 13px;
  fill: ${({ theme }) => theme.palette.green};
`

export const Crossmark = styled(BaseCrossmark)`
  height: 13px;
  width: 13px;
  fill: ${({ theme }) => theme.palette.red};
`

const Wrapper = styled(X)`
  > span {
    margin-left: 10px;
  }
`

const Loader = ({ loading, text, failure }) => {
  const Icon = () => {
    if (loading) {
      return <Spinner small />
    }
    return failure ? <Crossmark /> : <Checkmark />
  }
  const animation = useSpring({ from: { opacity: 0 }, opacity: 1 })

  return (
    <Wrapper as={animated.div} style={animation}>
      <Icon />
      <span>{text}</span>
    </Wrapper>
  )
}

export default memo(Loader)
