import React, { useReducer } from 'react'

import {
  Arrow,
  GHStyledLink as StyledLink,
  TruncatedCell,
  TitledCard,
  Table,
} from '@gitguardian/gg-front'

import ScanResult from '../ScanResult'

import getRepositoryBlock from './RepoUtil'

const initialState = {}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'OPEN': {
      const { id } = payload
      return { ...state, [id]: true }
    }
    case 'TOGGLE': {
      const { id } = payload
      return { ...state, [id]: !state[id] }
    }
    case 'RESET':
      return initialState
    default:
      throw new Error(`Type ${type} unknown`)
  }
}

const RepositoriesTable = ({
  repos,
  isOwner,
  scanStatuses,
  scannable,
  scanRepository,
}) => {
  const [expanded, dispatch] = useReducer(reducer, initialState)
  const columns = [
    {
      Cell: TruncatedCell,
      Header: 'Name',
      accessor: 'name',
      minWidth: 350,
    },
    {
      Cell: ({ original }) => {
        const RepositoryBlock = getRepositoryBlock(
          isOwner,
          original.webhook_state
        )
        return <RepositoryBlock />
      },
      Header: 'Protection',
      accessor: 'status',
      minWidth: 250,
    },
    {
      Expander: ({ original, isExpanded, viewIndex }) => {
        const scanStatus = scanStatuses[original.id]
        if (scanStatus) {
          if (expanded[viewIndex] === undefined) {
            dispatch({
              payload: {
                id: viewIndex,
              },
              type: 'OPEN',
            })
          }
          return (
            <div
              onClick={() =>
                dispatch({
                  payload: {
                    id: viewIndex,
                  },
                  type: 'TOGGLE',
                })
              }
            >
              <Arrow direction={isExpanded ? 'up' : 'down'} size="24" />
            </div>
          )
        }
        if (
          scannable(original.id) ||
          (scanStatus && scanStatus.status_code === 'error')
        ) {
          return (
            <StyledLink
              as="div"
              onClick={() => {
                scanRepository(original.id)
                dispatch({
                  payload: {
                    id: viewIndex,
                  },
                  type: 'OPEN',
                })
              }}
            >
              Scan
            </StyledLink>
          )
        }
        return null
      },
      Header: 'Action',
      expander: true,
      width: 90,
    },
  ]

  return (
    <TitledCard gridColumn="1 / -1" title="Table of repositories">
      <Table
        collapseOnDataChange={false}
        columns={columns}
        data={repos}
        expanded={expanded}
        noDataText="No repositories"
        onExpandedChange={null}
        onPageChange={() => dispatch({ type: 'RESET' })}
        SubComponent={({ original }) => (
          <ScanResult
            key={`${original.id}_scan`}
            repo_id={original.id}
            scanStatus={scanStatuses[original.id]}
          />
        )}
      />
    </TitledCard>
  )
}

export default RepositoriesTable
