import React, { Suspense } from 'react'
import 'styled-components/macro'

import { Center, Text } from '@gitguardian/gg-front'

import { Wrapper } from '../../components/PageUtils'
import { ReactComponent as Base404Text } from '../../assets/404/404-text.svg'

import { SVGWrapper, Background, Stars, Card } from './NotFound.style'

const Animation = React.lazy(() => import('./404Animation'))

const NotFound = () => (
  <Wrapper>
    <Card>
      <Center>
        <div>
          <Base404Text maxHeight={140} />
          <Text css="text-align:center;" typo="subtitle">
            Oops page not found
          </Text>
        </div>
      </Center>
      <Center>
        <SVGWrapper>
          <Background />
          <Stars />
          <Suspense fallback={<div css="height: 500px;" />}>
            <Animation />
          </Suspense>
        </SVGWrapper>
      </Center>
    </Card>
  </Wrapper>
)

export default NotFound
