import styled from 'styled-components/macro'
import { X } from '@gitguardian/gg-front'

import { ReactComponent as BasePlus } from '../../assets/plus.svg'

export const Wrapper = styled.div`
  position: relative;
  margin-top: 70px;
`

export const Base = styled.div`
  display: flex;
  cursor: pointer;
`

export const Img = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;

  margin-right: 20px;
`

export const Info = styled.div`
  color: ${({ theme }) => theme.palette.white};
  ${({ theme }) => ({ ...theme.typography.text, lineHeight: 1.2 })}
`

export const Login = styled.div`
  width: 90px;

  color: ${({ theme, color = 'darkPrimary' }) => theme.palette[color]};
  ${({ theme }) => ({
    ...theme.typography.smallerSubtitle,
    lineHeight: 1.2,
  })}
  ${({ theme }) => theme.mixin.truncateText}
`

export const Dropdown = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  z-index: 1000;
  padding: 10px 0;
  user-select: none;
  margin-top: 20px;

  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 6px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.09);
`

Dropdown.Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.palette.border};
  }
`

Dropdown.Link = styled(Dropdown.Item)`
  display: flex;
  color: ${({ theme }) => theme.palette.text};

  text-decoration: none;
`

Dropdown.Content = styled.p`
  color: ${({ theme }) => theme.palette.text};
  ${({ theme }) => theme.typography.smallerSubtitle};
  ${({ theme }) => theme.mixin.truncateText}
`

Dropdown.Img = styled(Img)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.palette.grey};
`

export const ContentWrapper = styled(X)`
  color: ${({ theme }) => theme.palette.text};
  overflow: hidden;

  > svg {
    fill: ${({ theme }) => theme.palette.text};
  }
`
export const Plus = styled(BasePlus)`
  fill: currentColor;
  height: 16px;
  width: auto;
  margin: 8px;
`
