import { call, takeEvery } from 'redux-saga/effects'

import { readEventsSuccess, readEventsFailure } from '../actions/events'

import { callViewEndpoint } from './utils'

const urlPrefix = '/events'

function* readEvents() {
  yield call(
    callViewEndpoint,
    urlPrefix,
    '',
    readEventsSuccess,
    readEventsFailure
  )
}

function* eventsSaga() {
  yield takeEvery('READ_EVENTS_REQUEST', readEvents)
}

export default eventsSaga
