import { connect } from 'react-redux'

import { joinSquadRequest } from '../../state/actions/squads'

import Invite from './Invite.component'

const mapStateToProps = state => ({
  loadingState: state.loading.JOIN_SQUAD,
})

const mapDispatchToProps = { joinSquad: joinSquadRequest }

export default connect(mapStateToProps, mapDispatchToProps)(Invite)
