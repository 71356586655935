import styled from 'styled-components'

import {
  GGLogoVerticalDevWhite,
  Layout as RawLayout,
} from '@gitguardian/gg-front'

const Layout = styled(RawLayout)`
  background-color: ${({ theme }) => theme.palette.background};
`

Layout.defaultProps = {
  navLogo: GGLogoVerticalDevWhite,
  navLogoHeight: 100,
  navWidth: '220px',
}

export default Layout
