import { connect } from 'react-redux'

import { signOut } from '../../state/actions/user'
import { viewModeSelector } from '../../state/selectors/views'

import Menu from './Menu.component'

const mapStateToProps = state => ({
  location: state.router.location,
  user: state.user,
  viewMode: viewModeSelector(state),
})

const mapDispatchToProps = {
  signOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
