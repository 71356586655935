const initialState = { fetched: false }

function scanStatuses(state = initialState, action) {
  switch (action.type) {
    case 'SCAN_REPOSITORY_REQUEST': {
      const { repositoryId } = action.payload

      return {
        ...state,
        [repositoryId]: {
          requested_at: new Date().toISOString(),
          status_code: 'running',
        },
      }
    }
    case 'READ_SCAN_RESULTS_FAILURE':
      return action.reason === 'timeout'
        ? {
            ...state,
            owned: state.owned.map(repo => {
              if (repo.id === action.repositoryId) {
                return {
                  ...repo,
                  webhook_state: 'timeout',
                }
              }
              return repo
            }),
          }
        : state
    case 'SCAN_REPOSITORY_SUCCESS':
    case 'READ_SCAN_RESULTS_SUCCESS': {
      const { repositoryId, result } = action.payload

      return {
        ...state,
        fetched: true,
        [repositoryId]: result,
      }
    }
    case 'SCAN_ALL_REPOSITORIES_SUCCESS':
    case 'READ_ALL_SCAN_RESULT_SUCCESS': {
      const newResults = Object.fromEntries(
        action.payload.map(result => [result.repository_id, result])
      )
      return { ...state, ...newResults, fetched: true }
    }
    default:
      return state
  }
}
export default scanStatuses
