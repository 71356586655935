import React from 'react'
import styled from 'styled-components/macro'
import {
  WelcomePage,
  Button,
  Subtitle,
  Y,
  Text,
  StyledLink,
} from '@gitguardian/gg-front'

const apiUrl = process.env.REACT_APP_API_URL

const Wrapper = styled(Y)`
  text-align: center;
  > :not(:first-child) {
    margin-top: 50px;
  }
`

const Authentication = () => (
  <WelcomePage>
    <Wrapper>
      <Subtitle>Welcome to GitGuardian</Subtitle>
      <Text>
        This application is deprecated, please go now to{' '}
        <StyledLink href="https://dashboard.gitguardian.com">
          dashboard.gitguardian.com
        </StyledLink>
      </Text>
      <Button
        as="a"
        bgColor="secondary"
        hoverBgColor="white"
        hoverTextColor="secondary"
        href="https://dashboard.gitguardian.com"
        textColor="white"
        withBorder
      >
        Visit the new App
      </Button>
      <Text>
        or click{' '}
        <StyledLink
          href={`${apiUrl}/auth/github/connect${window.location.search}`}
        >
          here
        </StyledLink>{' '}
        to access this application.
      </Text>
    </Wrapper>
  </WelcomePage>
)

export default Authentication
