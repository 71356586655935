import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'
import { format, parseISO } from 'date-fns'
import {
  Button,
  Loader,
  Title,
  Center,
  TitledCard,
  SquadButton,
  pluralize,
} from '@gitguardian/gg-front'

import ErrorBoundary from '../../components/ErrorBoundary'

import { shortDateFormat } from '../../utils/time'
import { getQueryParam } from '../../utils/utils'
import { errorMessages } from '../../utils/config'

import HistoryGraph from './HistoryGraph'
import { Refresh, FlatFigures } from './Dashboard.style'
import { Wrapper } from '../../components/PageUtils'

import SecretTable from './SecretTable'
import EventTable from './EventTable'

const sortByGgCreatedAt = (a, b) => (a.gg_created_at < b.gg_created_at ? 1 : -1)
const REFRESH_DEBOUNCE_DELAY = 2000

export const Info = styled.div`
  margin-right: 1rem;
  position: relative;
`

const DashboardScene = ({
  isPendingMember,
  updateLeakStatus,
  readLeaks,
  readCommitsBins,
  readRepositoriesCount,
  readAnalysedCommitsCount,
  readEvents,
  stats,
  createdAt,
  currentViewId,
  events,
  leaks,
  viewMode,
  location,
  newNotification,
}) => {
  const [refresh, setRefresh] = useState(false)

  const fetchData = useCallback(() => {
    readLeaks()
    readCommitsBins()
    readRepositoriesCount()
    readAnalysedCommitsCount()
    readEvents()
  }, [
    readAnalysedCommitsCount,
    readCommitsBins,
    readEvents,
    readLeaks,
    readRepositoriesCount,
  ])
  useEffect(fetchData, [currentViewId, fetchData])
  useEffect(() => {
    const errorParam = getQueryParam(location.search, 'error')
    if (errorParam) {
      const error = errorParam[1]
      newNotification({
        message: errorMessages[error] ? errorMessages[error] : error,
        type: 'error',
      })
    }
  }, [location.search, newNotification])

  const handleRefreshClick = () => {
    setRefresh(true)
    fetchData()
    setTimeout(() => setRefresh(false), REFRESH_DEBOUNCE_DELAY)
  }

  const sortedEvents = events.slice().sort(sortByGgCreatedAt)
  const sortedLeaks = leaks.slice().sort(sortByGgCreatedAt)
  const figures = stats && [
    {
      color: viewMode === 'user' ? 'primary' : 'squadSecondary',
      figure: stats.repositoriesCount || 0,
      label: `${viewMode === 'user' ? 'owned' : 'squad'} ${pluralize(
        'repository',
        stats.repositoriesCount
      )}`,
    },
    {
      color: viewMode === 'user' ? 'primary' : 'squadSecondary',
      figure: stats.analysedCommitsCount || 0,
      label: `scanned ${pluralize('commit', stats.analysedCommitsCount)}`,
    },
    {
      color: viewMode === 'user' ? 'primary' : 'squadSecondary',
      figure: format(parseISO(createdAt), shortDateFormat),
      label: `setup date`,
    },
  ]

  return (
    <Wrapper>
      <Title>Dashboard</Title>
      <div
        css={`
          grid-column: 1 / -1;
        `}
      >
        <Refresh
          animate={refresh}
          as={viewMode === 'user' ? Button : SquadButton}
          borderColor={viewMode === 'user' ? 'lightPrimary' : 'squadSecondary'}
          onClick={handleRefreshClick}
          withBorder
        >
          Refresh
          <i className="fas fa-sync-alt" />
        </Refresh>
      </div>
      {stats && stats.repositoriesCount != null ? (
        <FlatFigures figures={figures} />
      ) : (
        <Center>
          <Loader />
        </Center>
      )}
      <TitledCard
        css="min-height: 300px;"
        gridColumn="1/-1"
        title="Commits scanned over the last two weeks"
      >
        {stats && stats.commitsBins && (
          <ErrorBoundary>
            <HistoryGraph commits={stats.commitsBins} />
          </ErrorBoundary>
        )}
      </TitledCard>
      <ErrorBoundary>
        <TitledCard gridColumn="1 /-1" title="Table of incidents">
          <SecretTable
            isPendingMember={isPendingMember}
            secrets={sortedLeaks}
            updateLeakStatus={updateLeakStatus}
          />
        </TitledCard>
      </ErrorBoundary>
      <ErrorBoundary>
        <TitledCard gridColumn="1/-1" title="Table of events">
          <EventTable events={sortedEvents} />
        </TitledCard>
      </ErrorBoundary>
    </Wrapper>
  )
}

export default DashboardScene
