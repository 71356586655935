import React, { useState, useEffect } from 'react'
import { format, parse } from 'date-fns'
import 'styled-components/macro'
import {
  Loader,
  Button,
  Center,
  Title,
  Text,
  SquadButton,
} from '@gitguardian/gg-front'

import { Wrapper } from '../../components/PageUtils'

import {
  TweetsGrid,
  Tweet,
  WriteTweet,
  TweetWrapper,
  WriteTweetWrapper,
} from './TwitterShare.style'

const TwitterShare = ({ tweets, viewMode, readTweets }) => {
  const [tweetToPost, setTweetToPost] = useState('')

  useEffect(() => readTweets(), [readTweets])

  const StyledButton = viewMode === 'user' ? Button : SquadButton
  return (
    <Wrapper>
      <Title className="TwitterShareScene-header">
        Sharing is caring! &lt;3
      </Title>
      <div css="grid-column: 1 / -1;">
        <Text>
          These amazing developers, security experts and executives have chosen
          to share with our community.
          <br />
          Help them raise awareness and make the web a more enjoyable and secure
          place to be.
        </Text>
        <Text css="margin-top:30px;" typo="smallerSubtitle">
          Good friends don&apos;t let their friends push code without
          GitGuardian
        </Text>

        <WriteTweetWrapper>
          <WriteTweet
            onChange={e => setTweetToPost(e.target.value)}
            placeholder="Show your support trough a nice tweet 🙏"
            value={tweetToPost}
          />
          <StyledButton
            as="a"
            borderColor={
              viewMode === 'user' ? 'lightPrimary' : 'squadSecondary'
            }
            href={`https://twitter.com/intent/tweet?url=https://gitguardian.com&text=${encodeURIComponent(
              tweetToPost
            )}`}
            rel="noopener noreferrer"
            target="_blank"
            withBorder
          >
            <i
              className="fab fa-twitter"
              css={`
                margin-right: 5px;
              `}
            />
            Tweet
          </StyledButton>
        </WriteTweetWrapper>
        <Text color="secondaryText" typo="caption">
          Latest tweets
        </Text>
      </div>
      <TweetsGrid options={{ fitWidth: true, gutter: 30 }}>
        {tweets.map(({ id_str, user, full_text, created_at }) => (
          <TweetWrapper key={id_str}>
            <Tweet
              avatar={user.profile_image_url_https}
              date={format(
                parse(created_at, 'EEE LLL dd HH:mm:ss xx y', new Date()),
                'dd MMMM yyyy'
              )}
              highlightColor={
                viewMode === 'user' ? 'lightPrimary' : 'secondary'
              }
              link={`https://twitter.com/${user.screen_name}/status/${id_str}`}
              name={user.name}
              pseudo={user.screen_name}
              tweet={full_text}
            />
          </TweetWrapper>
        ))}
      </TweetsGrid>
      {!tweets.length && (
        <Center>
          <Loader />
        </Center>
      )}
    </Wrapper>
  )
}

export default TwitterShare
