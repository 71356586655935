import styled from 'styled-components/macro'

import { Title, TitledCard, FlatterCard } from '@gitguardian/gg-front'

import Grid from './Grid'

export const Wrapper = styled(Grid)`
  padding: 30px;

  > :not(:first-child) {
    margin-bottom: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.S}) {
    padding: 50px 0;
  }

  ${Title} {
    grid-column: 1 / -1;
    margin-bottom: 30px;
  }

  ${FlatterCard} {
    max-width: 100%;
  }
`

export const Filter = styled(TitledCard)`
  grid-column: 1;
  align-self: start;

  @media (max-width: ${({ theme }) => theme.breakpoint.S}) {
    grid-column: 1 / -1;
  }
`
