import { combineReducers, applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { notificationsReducer, notificationsSaga } from '@gitguardian/gg-front'

import history from '../history'

import events from './reducers/events'
import leaks from './reducers/leaks'
import repositories from './reducers/repositories'
import scanStatuses from './reducers/scanStatuses'
import loading from './reducers/loading'
import squads from './reducers/squads'
import stats from './reducers/stats'
import tweets from './reducers/tweets'
import user from './reducers/user'
import views from './reducers/views'
import errorsSaga from './sagas/errors'
import eventsSaga from './sagas/events'
import leaksSaga from './sagas/leaks'
import repositoriesSaga from './sagas/repositories'
import statsSaga from './sagas/stats'
import tweetsSaga from './sagas/tweets'
import userSaga from './sagas/user'
import viewsSaga from './sagas/views'
import { GAMiddleware } from './middlewares'
import squadsSaga from './sagas/squads'

const persistConfig = {
  key: 'views',
  storage,
}

/* Create reducer */
const reducer = combineReducers({
  events,
  leaks,
  loading,
  notifications: notificationsReducer,
  repositories,
  router: connectRouter(history),
  scanStatuses,
  squads,
  stats,
  tweets,
  user,
  views: persistReducer(persistConfig, views),
})

/* Create Saga */
function* rootSaga() {
  yield all([
    errorsSaga(),
    eventsSaga(),
    leaksSaga(),
    repositoriesSaga(),
    squadsSaga(),
    statsSaga(),
    tweetsSaga(),
    userSaga(),
    viewsSaga(),
    notificationsSaga(),
  ])
}

/* Create store */
const sagaMiddleware = createSagaMiddleware()
const enhancer = applyMiddleware(
  GAMiddleware,
  routerMiddleware(history),
  sagaMiddleware
)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composeEnhancers(enhancer))

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
