import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import withSizes from 'react-sizes'

import { onboardingNextStep } from '../../state/actions/user'
import { breakpoint } from '../../theme'

import OnboardingTracker from './OnboardingTracker.component'

const mapStateToProps = ({ user }) => ({
  user,
})

const mapDispatchToProps = {
  onboardingNextStep,
  push,
}

const mapSizesToProps = ({ width }) => ({
  smallScreen: width < breakpoint.Mdigit,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(OnboardingTracker))
