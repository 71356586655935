import React from 'react'
import { APIProviders, Title, Text } from '@gitguardian/gg-front'
import { Wrapper } from '../../components/PageUtils'

const Page = () => (
  <Wrapper>
    <Title>Supported credentials</Title>
    <Text>
      More than 200 API providers are actually supported by GitGuardian and this
      number increases every day thanks to the community of developers{' '}
    </Text>
    <APIProviders />
  </Wrapper>
)

export default Page
