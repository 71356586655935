import { connect } from 'react-redux'

import { readUser } from '../../state/actions/user'
import { readSquads } from '../../state/actions/views'
import { squadsSelector } from '../../state/selectors/views'

import AuthenticationBouncer from './AuthenticationBouncer.component'

const mapStateToProps = state => ({
  squads: squadsSelector(state),
  user: state.user,
})

const mapDispatchToProps = {
  readSquads,
  readUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationBouncer)
