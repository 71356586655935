/*
  enhanceSagaWithOnCompletion
  enhances a saga so its firing actions can have an onCompletion metadata
  that fires when the saga completes
*/
function enhanceSagaWithOnCompletion(saga) {
  return function* enhancedSaga(action) {
    yield* saga(action)

    if (typeof action.onCompletion === 'function') {
      yield action.onCompletion()
    }
  }
}

export default enhanceSagaWithOnCompletion
