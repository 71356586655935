import React, { Component } from 'react'
import 'styled-components/macro'
import { X, Y } from '@gitguardian/gg-front'

import {
  NextButton,
  Skip,
  WrapperSmallScreen,
  StepCircle,
  XDivider,
  StepName,
  Step,
} from './OnboardingTracker.style'
import { ENABLE_SQUADS } from '../../utils/config'

/*
  OnboardingTracker
  a component that regulates the onboarding and displays the onboarding steps
*/

const steps = [
  {
    name: 'Settings',
    url: '/settings',
  },
  {
    name: 'Owned repositories',
    url: '/owned-repositories',
  },
  {
    name: 'Collaborating repositories',
    url: '/collaborating-repositories',
  },
  {
    name: 'Share on Twitter',
    url: '/share-twitter',
  },
]

if (ENABLE_SQUADS) {
  steps.splice(3, 0, {
    name: 'Squad',
    url: '/squad',
  })
}

class OnboardingTracker extends Component {
  componentDidMount() {
    this.forceLocation()
  }

  componentDidUpdate() {
    this.forceLocation()
  }

  // Force window.location on update
  forceLocation() {
    const { user, push, location } = this.props

    if (!user) {
      return
    }

    const onboardingStepIndex = user.onboarding_step_index

    if (onboardingStepIndex !== -1) {
      const correctUrl = steps[onboardingStepIndex].url

      if (location.pathname !== correctUrl) {
        push(`${steps[onboardingStepIndex].url}?onboarding`)
      }
    }
  }

  handleNextStepClick = () => {
    const { user } = this.props
    const onboardingStepIndex = user.onboarding_step_index
    const finishedOnboarding = onboardingStepIndex + 1 === steps.length
    if (finishedOnboarding) {
      this.handleFinish()
    } else {
      this.handleGoToStep(onboardingStepIndex + 1)
    }
  }

  handleCloseClick = () => {
    const { user, onboardingNextStep } = this.props
    const onboardingStepIndex = user.onboarding_step_index
    onboardingNextStep(-1, steps[onboardingStepIndex].url)
  }

  handleFinish = () => {
    const { onboardingNextStep } = this.props
    onboardingNextStep(-1, '/')
  }

  handleGoToStep = i => {
    const { onboardingNextStep } = this.props
    onboardingNextStep(i, `${steps[i].url}?onboarding`)
  }

  renderButtons() {
    const { user } = this.props
    const lastStep = user.onboarding_step_index === steps.length - 1

    return (
      <Y>
        {lastStep ? (
          <NextButton onClick={this.handleFinish}>Finish</NextButton>
        ) : (
          <>
            <NextButton
              disabled={!user.emails.length}
              onClick={this.handleNextStepClick}
            >
              Next step
            </NextButton>
            <Skip onClick={this.handleCloseClick}>Skip onboarding</Skip>
          </>
        )}
      </Y>
    )
  }

  Small = ({ currentStep }) => {
    const { user } = this.props
    const i = user.onboarding_step_index
    const first = i === 0
    const last = i === steps.length - 1

    return (
      <WrapperSmallScreen>
        <X>
          {!first && (
            <>
              <StepCircle
                key={`step_circle_${i - 1}`}
                onClick={() => this.handleGoToStep(i - 1)}
              >
                {i}
              </StepCircle>
              <XDivider key={`step_separator_${i - 1}`} />
            </>
          )}
          <StepCircle key={`step_circle_${i}`} selected>
            {i + 1}
          </StepCircle>
          <StepName key="step_name" selected>
            {currentStep.name}
          </StepName>
          {!last && (
            <>
              <XDivider key={`step_separator_${i + 1}`} />
              <StepCircle
                key={`step_circle_${i + 1}`}
                onClick={() => this.handleGoToStep(i + 1)}
              >
                {i + 2}
              </StepCircle>
            </>
          )}
        </X>

        <div>{this.renderButtons()}</div>
      </WrapperSmallScreen>
    )
  }

  Large = () => {
    const { user } = this.props
    const currentIndex = user.onboarding_step_index

    return (
      <div
        css={`
          margin-top: 100px;

          ${NextButton} {
            margin-top: 100px;
          }
        `}
      >
        {steps.map((step, i) => (
          <Step key={i} onClick={() => this.handleGoToStep(i)}>
            <StepCircle selected={currentIndex === i}>{i + 1}</StepCircle>
            <StepName selected={currentIndex === i}>{steps[i].name}</StepName>
          </Step>
        ))}
        <div>{this.renderButtons()}</div>
      </div>
    )
  }

  render() {
    const { user, location, smallScreen } = this.props

    if (!user) {
      return null
    }

    const i = user.onboarding_step_index

    if (i === -1) {
      return null
    }

    const currentStep = steps[i]

    if (location.pathname !== currentStep.url) {
      return null
    }

    return (
      <>
        <this.Large />
        {smallScreen && <this.Small currentStep={currentStep} />}
      </>
    )
  }
}

export default OnboardingTracker
