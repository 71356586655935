import React, { memo } from 'react'

import { X, Text } from '@gitguardian/gg-front'

import Tooltip from '../Tooltip'
import InfoLogo from '../../assets/info-icon.svg'
import { Checkmark, Info, Crossmark } from './RepositoriesTable.style'

const BaseRepo = ({ children }) => <X>{children}</X>

const BaseTooltip = memo(({ children }) => (
  <Info>
    <Tooltip content={children} placement="right">
      <img alt="info" height={16} src={InfoLogo} />
    </Tooltip>
  </Info>
))

export const MonitoredRepository = memo(() => (
  <BaseRepo>
    <Checkmark />
    <Text>Real-time protection activated</Text>
  </BaseRepo>
))

const ArchivedRepository = memo(() => (
  <BaseRepo>
    <Checkmark />
    <Text>Archived repository</Text>
    <BaseTooltip>
      <Text>
        Archived repositories are read-only, therefore you cannot push on it,{' '}
        <br />
        no need for real-time protection. You can still scan it.
      </Text>
    </BaseTooltip>
  </BaseRepo>
))

const LoadingRepository = memo(() => (
  <BaseRepo>
    <i
      className="fas fa-spinner fa-spin"
      css={`
        color: ${({ theme }) => theme.palette.secondary};
        margin-right: 10px;
      `}
    />
    <Text>Real time protection pending...</Text>
  </BaseRepo>
))

const NotMonitoredRepository = memo(() => (
  <BaseRepo>
    <Checkmark />
    <Text>Near real time protection</Text>
    <BaseTooltip>
      <Text>
        Under some circumstances we may be unable to setup a webhook.
        <br />
        But rest assured we also scan all github public activity so we got you
        covered.
      </Text>
    </BaseTooltip>
  </BaseRepo>
))

const CollaboratingRepository = memo(() => (
  <BaseRepo>
    <Crossmark />
    <Text>Not protected</Text>
    <BaseTooltip>
      <Text>
        We can only protect your own repositories.
        <br />
        To protect the repositories of another person or organization, please
        consider inviting them to GitGuardian.
      </Text>
    </BaseTooltip>
  </BaseRepo>
))

const getRepositoryBlock = (isOwner, webhookState) => {
  if (!isOwner) {
    return CollaboratingRepository
  }
  switch (webhookState) {
    case 'set':
      return MonitoredRepository
    case 'archived':
      return ArchivedRepository
    case 'pending':
      return LoadingRepository
    default:
      return NotMonitoredRepository
  }
}

export default getRepositoryBlock
