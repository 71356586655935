import styled, { css } from 'styled-components/macro'

export const Lead = styled.div`
  color: ${({ theme }) => theme.palette.text};
  ${({ theme }) => theme.typography.smallerSubtitle};
`
const Tab = styled.div`
  margin-left: 25px;
`

const NotificationColor = css`
  ${({ theme, type }) => {
    switch (type) {
      case 'success':
        return `color: ${theme.palette.green};`

      case 'danger':
        return `color: ${theme.palette.red};`

      case 'info':
        return `color: ${theme.palette.secondary};`

      default:
        return `color: ${theme.palette.red};`
    }
  }}
`
export const Match = styled(Tab)`
  word-break: break-all;
  ${NotificationColor}
`
export const File = styled.li`
  margin-bottom: 30px;
`

export const Row = styled.div`
  grid-column: 2 / span 6;
`

export const LeakTitle = styled.div`
  padding: 30px;
  border: 2px solid ${({ theme }) => theme.palette.grey3};
`

export const Wrapper = styled.div`
  padding: 20px;

  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 1px;
  background-color: ${({ theme }) => theme.palette.grey3};

  ${({ theme }) => theme.typography.text};
`
